import { useEffect, useState } from 'react'

import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { OnFileUploadInput } from 'design-system/UploadArea/UploadArea'
import { Button, Image, Modal, Select } from 'design-system/components'

import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import { UseFetchConfig } from '@/App/clients/http/interface'

import {
  DownloadDocumentsModalCancelButtonDiv,
  DownloadDocumentsModalSaveButtonDiv,
  FilePreviewDiv,
  ModalActionButtonsDiv,
  NameHeader,
  PreviewImageDiv,
  TableBody,
  TableHeader,
  TdActions,
  TdFileName,
  TdFileType,
  TypeHeader,
} from './style'

export interface DocumentUploadModalProps {
  visibility: boolean
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>
  documents: OnFileUploadInput[]
  setUploadFilesRequestConfigs: React.Dispatch<React.SetStateAction<UseFetchConfig[]>>
  isLoadingUploadFiles: boolean
  orderId?: string | null
  entityId?: string | null
}
export const DocumentUploadModal: React.FC<DocumentUploadModalProps> = ({
  visibility,
  setVisibility,
  documents,
  setUploadFilesRequestConfigs,
  isLoadingUploadFiles,
  orderId,
  entityId,
}) => {
  const [uploadedDocuments, setUploadedDocuments] = useState(documents)
  const [fileTypes, setFileTypes] = useState<Array<string | undefined>>(
    documents.map(() => undefined),
  )

  useEffect(() => {
    setUploadedDocuments(documents)
    setFileTypes(documents.map(() => undefined))
  }, [documents])

  useEffect(() => {
    if (fileTypes.length === 0) setVisibility(false)
  }, [fileTypes])

  const selectDefaultValue = 'Selecione o tipo do documento'
  const documentTypeOptions = [
    {
      label: 'CPF',
      value: 'CPF',
    },

    {
      label: 'Carta de permanência',
      value: 'Carta de permanência',
    },
    {
      label: 'Carteirinha do último plano',
      value: 'Carteirinha do último plano',
    },
    {
      label: 'Cartão do SUS',
      value: 'Cartão do SUS',
    },
    {
      label: 'Comprovante de pagamento (plano anterior)',
      value: 'Comprovante de pagamento (plano anterior)',
    },
    {
      label: 'Comprovante de residência',
      value: 'Comprovante de residência',
    },
    {
      label: 'Comprovante de vínculo',
      value: 'Comprovante de vínculo',
    },
    {
      label: 'Documento vinculado a entidade',
      value: 'Documento vinculado a entidade',
    },
    {
      label: 'Ficha de associação',
      value: 'Ficha de associação',
    },
    {
      label: 'Contrato social',
      value: 'Contrato social',
    },
    {
      label: 'Guia quitada',
      value: 'Guia quitada',
    },
    {
      label: 'Cartão CNPJ',
      value: 'Cartão CNPJ',
    },
    {
      label: 'Relação do FGTS',
      value: 'Relação do FGTS',
    },
    {
      label: 'FGTS',
      value: 'FGTS',
    },
  ]

  const handleDocumentsUpload = () => {
    const uploadFilesRequestConfigs = uploadedDocuments.map((file, index) => {
      const formData = new FormData()
      formData.append('fileName', file.fileName)
      if (fileTypes[index]) {
        formData.append('fileType', fileTypes[index] as string)
      }

      return HealthPlansOrdersRequests.uploadDocumentOrderEntity(formData, orderId, entityId)
    })

    setUploadFilesRequestConfigs(uploadFilesRequestConfigs)
  }

  const handleTypeChange = (value: string, index: number) => {
    setFileTypes([...fileTypes.slice(0, index), value, ...fileTypes.slice(index + 1)])
  }

  const handleDocumentDelete = (index: number) => {
    setFileTypes(fileTypes.filter((_, fileIndex) => fileIndex !== index))
    setUploadedDocuments(uploadedDocuments.filter((_, fileIndex) => fileIndex !== index))
  }

  return (
    <Modal
      isOpen={visibility}
      setIsOpen={setVisibility}
      footer={
        <ModalActionButtonsDiv>
          <DownloadDocumentsModalCancelButtonDiv>
            <Button palette="cancel" onClick={() => setVisibility(false)}>
              <CloseCircleOutlined />
              Cancelar
            </Button>
          </DownloadDocumentsModalCancelButtonDiv>

          <DownloadDocumentsModalSaveButtonDiv>
            <Button
              onClick={() => handleDocumentsUpload()}
              loading={isLoadingUploadFiles}
              disabled={!fileTypes.every((type) => type != null && type !== selectDefaultValue)}
            >
              Salvar
            </Button>
          </DownloadDocumentsModalSaveButtonDiv>
        </ModalActionButtonsDiv>
      }
      bodyStyle={{ display: 'flex', flexDirection: 'column', height: '380px' }}
      width={1045}
    >
      <TableHeader>
        <NameHeader>
          <p>Arquivo</p>
        </NameHeader>
        <TypeHeader>
          <p>Tipo de documento</p>
        </TypeHeader>
      </TableHeader>
      <TableBody>
        {uploadedDocuments?.map((file, index) => {
          return (
            <div key={index}>
              {index !== 0 && <p />}
              <tr key={index}>
                <TdFileName>
                  <FilePreviewDiv>
                    <PreviewImageDiv>
                      <Image src={URL.createObjectURL(file.fileContent)} />
                    </PreviewImageDiv>
                    {file.fileName}
                  </FilePreviewDiv>
                </TdFileName>

                <TdFileType>
                  <Select
                    defaultValue={selectDefaultValue}
                    value={fileTypes[index]}
                    onChange={(value) => handleTypeChange(value as string, index)}
                    options={documentTypeOptions}
                  />
                </TdFileType>
                <TdActions>
                  <DeleteOutlined onClick={() => handleDocumentDelete(index)} />
                </TdActions>
              </tr>
            </div>
          )
        })}
      </TableBody>
    </Modal>
  )
}
