import { DispatchEvent, GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { calculateProcessingTime } from '@/App/utils/calculateProcessingTime'

import {
  IconWrapper,
  StyledDescriptionError,
  StyledDescriptionSuccess,
  StyledDispatchInProgress,
  SubtitleStatus,
  TitleStatus,
} from './style'

interface DispatchInfoProps {
  dispatchEvent: DispatchEvent | undefined
  orderData: GetOrderInformationBody | undefined
}

export const DispatchInfos: React.FC<DispatchInfoProps> = ({ dispatchEvent, orderData }) => {
  const processingTime = calculateProcessingTime(
    dispatchEvent?.createdAt,
    dispatchEvent?.processingStatus,
    dispatchEvent?.updatedAt,
  )

  const showErrorReason = ['insurer_processing_error', 'FAILED', 'ERROR'].includes(
    dispatchEvent?.processingStatus ?? '',
  )
  const event = orderData?.status === 'DISPATCH_IN_PROGRESS' ? 'Emissão' : 'Cotação'
  const allowedInsurers = ['Amil', 'Porto Seguro']

  return (
    <>
      <StyledDispatchInProgress>
        <IconWrapper>{dispatchEvent?.Icon()}</IconWrapper>
        {dispatchEvent?.processingStatus === 'PENDING' ? (
          <div>
            <TitleStatus>Status de Processamento da {event}:</TitleStatus>
            <div>
              <TitleStatus>{dispatchEvent?.label}</TitleStatus>
              <SubtitleStatus>{processingTime}</SubtitleStatus>
            </div>
          </div>
        ) : (
          <div>
            {event === 'Emissão' && orderData?.quotation?.insurer === 'Amil' && (
              <div>
                <TitleStatus>ID Oportunidade:</TitleStatus>
                <TitleStatus>{dispatchEvent?.insurerOpportunityId}</TitleStatus>
              </div>
            )}
            {event === 'Emissão' &&
              allowedInsurers.includes(orderData?.quotation?.insurer ?? '') && (
                <div>
                  <TitleStatus>ID Prop:</TitleStatus>
                  <TitleStatus>{dispatchEvent?.insurerOrderId}</TitleStatus>
                </div>
              )}

            <div>
              <TitleStatus>Status da {event}: </TitleStatus>
              <TitleStatus>{dispatchEvent?.label}</TitleStatus>
              <SubtitleStatus>{processingTime}</SubtitleStatus>
              {event === 'Cotação' && dispatchEvent?.processingStatus === 'SUCCESS' && (
                <StyledDescriptionSuccess>
                  {orderData?.quotation?.insurer !== 'Notre Dame Intermédica' && (
                    <div>
                      <TitleStatus>Cotação:</TitleStatus>
                      <SubtitleStatus>{dispatchEvent?.insurerOrderId}</SubtitleStatus>
                    </div>
                  )}
                  <div>
                    <TitleStatus>Valor da cotação + IOF: </TitleStatus>
                    <SubtitleStatus>R$ {dispatchEvent?.amount}</SubtitleStatus>
                  </div>
                </StyledDescriptionSuccess>
              )}
            </div>
            {showErrorReason && (
              <StyledDescriptionError>
                <TitleStatus>Motivo</TitleStatus>
                <SubtitleStatus>{`->${dispatchEvent?.errorReason}`}</SubtitleStatus>
              </StyledDescriptionError>
            )}
          </div>
        )}
      </StyledDispatchInProgress>
    </>
  )
}
