import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { GetManagersDto } from '../dtos'

async function getManagers({ signal }: QueryFunctionContext) {
  const response = await api.get<GetManagersDto>(
    `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/managers`,
    {
      signal,
      headers: { Authorization: localStorage.getItem('authToken') },
    },
  )
  return response.data
}

export function getManagersOptions() {
  return queryOptions({
    queryKey: ['managers'] as const,
    queryFn: getManagers,
  })
}
