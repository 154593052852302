import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { PlusOutlined, SettingOutlined } from '@ant-design/icons'
import { useVariant } from '@unleash/proxy-client-react'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Button, Modal, Select, Skeleton, Spinner, Tabs, Toast } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { DispatchEvent } from '@/App/clients/healthPlansOrders/dtos'
import { useFillQuotationInformation } from '@/App/clients/healthPlansOrders/mutations/fillQuotaionInformation'
import { useUploadFileByURL } from '@/App/clients/healthPlansOrders/mutations/uploadFileByUrl'
import { useUploadOrderDocument } from '@/App/clients/healthPlansOrders/mutations/uploadOrderDocument'
import { useOrderDispatchEvents } from '@/App/clients/healthPlansOrders/queries/orderDispatchEvents'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import useFetch from '@/App/clients/http'
import { useUpdateStatus } from '@/App/clients/orderState/mutation/updateStatus'
import { usePossibleStatusesQuery } from '@/App/clients/orderState/queries/listPossibleStates'
import { PlanCardComponent } from '@/App/components/Cards/PlanCard'
import { AnalysisModal } from '@/App/components/Modal/OrderStatus/Analysis'
import { BillPendingModal } from '@/App/components/Modal/OrderStatus/BillPaymentPending'
import { CancelModal } from '@/App/components/Modal/OrderStatus/CanceledOrder'
import { OrderDispatchingModal } from '@/App/components/Modal/OrderStatus/OrderDispatching'
import { QuotationInsurerModal } from '@/App/components/Modal/OrderStatus/QuotationInsurer'
import { RiskAnalysisModal } from '@/App/components/Modal/OrderStatus/RiskAnalysis'
import { UpdateStatusDescriptionModal } from '@/App/components/Modal/OrderStatus/UpdateStatusDescription'
import { WaitingSignatureModal } from '@/App/components/Modal/OrderStatus/WaitingSignature'
import { OrderScoreTag } from '@/App/components/OrderScoreTag'
import { ActivityTab } from '@/App/components/orderTabs/ActivitiesTab/ActivityTab'
import { LeadDataTab } from '@/App/components/orderTabs/LeadDataTab/LeadDataTab'
import { ProposalTab } from '@/App/components/orderTabs/ProposalTab/ProposalTab'
import { ResponsibleAdminTab } from '@/App/components/orderTabs/ResponsibleAdminTab/responsibleAdminsTab'
import { TimeLineTab } from '@/App/components/orderTabs/TimeLineTab/timeLineTab'
import { useDynamicForm } from '@/App/contexts/dynamicForm/DynamicFormProvider'
import { BLOCKED_STATUSES_IN_REGISTERED_IN_INSURER } from '@/App/helpers/constants'
import { statusMapperFn } from '@/App/helpers/statusMapper'
import { MIN_LIFE_QUANTITY_ALLOWED } from '@/App/utils/constants'
import { urlToBlob } from '@/App/utils/urlToBlob'

import { DispatchInfos } from './dispatchInfo'
import { getRequiredFieldsAlice } from './entities/entityAccordion/helper/alice/requiredFields'
import { getRequiredFieldsAmil } from './entities/entityAccordion/helper/amil/requiredFields'
import { getRequiredFieldsBradesco } from './entities/entityAccordion/helper/bradesco/requiredFields'
import { getRequiredFieldsGndi } from './entities/entityAccordion/helper/gndi/requiredFields'
import { getRequiredFieldsPorto } from './entities/entityAccordion/helper/porto/requiredFields'
import { getRequiredFieldsSisweb } from './entities/entityAccordion/helper/sisweb/requiredFields'
import { getRequiredFieldsSulamerica } from './entities/entityAccordion/helper/sulamerica/requiredFields'
import { getRequiredFieldsQuotationSulamerica } from './entities/entityAccordion/helper/sulamerica/requiredFieldsQuotation'
import {
  ContentTabSkeleton,
  HorizontalOrderTags,
  LabelTabsItem,
  MainContent,
  OrderDiv,
  OrderHeader,
  OrderHeaderContent,
  OrderHeaderInfosContainer,
  OrderStatusSelect,
  OrderStatusSelectLoadingDiv,
  OrderTag,
  OrderTagsDiv,
  RequestTag,
  StyledOptionLabel,
  StyledSkeletonDiv,
} from './style'

dayjs.extend(customParseFormat)

export const OrderDetailsPage = () => {
  const [desiredOrderStatus, setDesiredOrderStatus] = useState<string>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [orderStatus, setOrderStatus] = useState<string | null>(null)
  const [responsibleAdminsQuantity, setResponsibleAdminsQuantity] = useState<number>()
  const [shouldFillResponsibleAdminsIds, setShouldFillResponsibleAdminsIds] = useState(false)
  const [shouldReloadTimelineRequest, setShouldReloadTimelineRequest] = useState(false)
  const [shouldReload, setShouldReload] = useState(false)
  const [isRegisteredInInternalSystem, setIsRegisteredInInternalSystem] = useState<boolean>()
  const [validateOrderDispatch, setValidateOrderDispatch] = useState<boolean>()
  const [validateSiswebDispatch, setValidateSiswebDispatch] = useState<boolean>()
  const [validateQuotationDispatch, setValidateQuotationDispatch] = useState<boolean>()
  const [isValidatingForms, setIsValidatingForms] = useState<boolean>()
  const [implantationEvent, setImplantationchEvent] = useState<DispatchEvent>()
  const [quotationEvent, setQuotationEvent] = useState<DispatchEvent>()
  const [isUploading, setIsUploading] = useState<boolean>()

  const { orderId } = useParams<{ orderId: string }>()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const insurersModal = [
    'BILL_PAYMENT_PENDING',
    'FILLING_NOT_MEET_REQUIREMENTS',
    'CANCELED',
    'WAITING_SIGNATURE',
    'ANALYSIS',
    'DISPATCH_IN_PROGRESS',
    'RISK_ANALYSIS',
    'QUOTATION_INSURER',
  ]

  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const fillQuotationInformationRequest = useFillQuotationInformation()
  const listPossibleStatusesQuery = usePossibleStatusesQuery(orderInformationQuery?.data?.status)
  const uploadOrderDocument = useUploadOrderDocument()
  const uploadFileByUrlMutation = useUploadFileByURL()

  const tab = searchParams.get('tab')

  const insurersQuotation = ['Porto Seguro', 'Bradesco', 'Sulamérica']
  const isInsurerQuotation =
    orderInformationQuery?.data?.quotation?.insurer &&
    insurersQuotation.includes(orderInformationQuery.data.quotation.insurer)

  const updateStatusMutation = useUpdateStatus(orderId)

  const {
    setRequestConfig: setFillIsRegisteredInInternalSystemRequestConfig,
    statusCode: fillIsRegisteredInInternalSystemStatusCode,
    isLoading: isLoadingFillIsRegisteredInInternalSystem,
    error: registerdInInternalSystemError,
  } = useFetch()

  const orderDispatchEventQuery = useOrderDispatchEvents({
    orderId,
    status: orderInformationQuery?.data?.status,
    dispatchStatus: orderInformationQuery.isSuccess,
  })

  const dynamicFormState = useDynamicForm()

  useEffect(() => {
    if (updateStatusMutation.isSuccess) {
      triggerToast(true, 'Status atualizado com sucesso')
    }
  }, [updateStatusMutation.isSuccess])

  useEffect(() => {
    if (validateOrderDispatch === true || validateSiswebDispatch === true) {
      triggerToast(
        false,
        'Preencha os dados obrigatórios para emissão automática. Após revisão, clique para solicitar novamente.',
      )
    }
  }, [validateOrderDispatch, validateSiswebDispatch])

  useEffect(() => {
    if (fillIsRegisteredInInternalSystemStatusCode === 200)
      triggerToast(true, 'Valor de cadastro no sisweb atualizado com sucesso!')
    else if (
      fillIsRegisteredInInternalSystemStatusCode &&
      fillIsRegisteredInInternalSystemStatusCode > 400
    )
      triggerToast(false, 'Ops, ocorreu um erro ao atualizar o valor do cadastro no sisweb')
  }, [fillIsRegisteredInInternalSystemStatusCode])

  useEffect(() => {
    if (registerdInInternalSystemError) {
      triggerToast(false, `Ops, ocorreu um erro atualizar o status`)
    }
  }, [registerdInInternalSystemError])

  useEffect(() => {
    orderInformationQuery.refetch
  }, [shouldReload])

  useEffect(() => {
    if (shouldFillResponsibleAdminsIds) {
      orderInformationQuery.refetch
    }
    setShouldFillResponsibleAdminsIds(false)
  }, [shouldFillResponsibleAdminsIds])

  useEffect(() => {
    if (orderInformationQuery.data != null) {
      setOrderStatus(orderInformationQuery?.data.status ?? null)
      setResponsibleAdminsQuantity(orderInformationQuery.data.responsibleAdmins?.length)

      setIsRegisteredInInternalSystem(
        orderInformationQuery?.data.isRegisteredInInternalSystem ?? false,
      )
    }
  }, [orderInformationQuery?.data, orderInformationQuery.isSuccess])

  useEffect(() => {
    if (updateStatusMutation.error) {
      triggerToast(false, `Ops, ocorreu um erro ao atualizar o status`)
    }
  }, [updateStatusMutation.error])

  useEffect(() => {
    if (orderDispatchEventQuery?.data && orderDispatchEventQuery.data.length > 0) {
      orderDispatchEventQuery.data.map((event) => {
        if (event.type === 'IMPLANTATION') {
          setImplantationchEvent(event)
        }
        if (event.type === 'QUOTATION') {
          setQuotationEvent(event)
          setIsUploading(true)
        }
      })
    }
  }, [orderDispatchEventQuery?.data])

  useEffect(() => {
    const isQuotationUploaded = orderInformationQuery?.data?.documents
      ?.map((doc) => doc.fileName)
      .includes(`Cotação - ${quotationEvent?.insurerOrderId}.pdf`)
    if (
      quotationEvent?.label === 'Concluído' &&
      !isQuotationUploaded &&
      quotationEvent?.quotationUrl != undefined &&
      isUploading
    ) {
      const formData = new FormData()
      formData.append('fileName', `Cotação - ${quotationEvent?.insurerOrderId}.pdf`)
      uploadOrderDocument.mutateAsync({
        data: formData,
        orderId: orderInformationQuery?.data?.id,
      })
      setIsUploading(false)
    }

    if (
      uploadOrderDocument.isSuccess &&
      quotationEvent?.quotationUrl != undefined &&
      !isQuotationUploaded
    ) {
      const url = quotationEvent?.quotationUrl
      urlToBlob(url)
        .then((blob) => {
          const uploadFileByUrlData = {
            uploadURL: uploadOrderDocument.data.uploadURL,
            data: blob,
            fileContentType: 'application/pdf',
          }
          uploadFileByUrlMutation.mutate(uploadFileByUrlData)
        })
        .finally(() => {
          orderInformationQuery.refetch()
        })
    }
  }, [quotationEvent, uploadOrderDocument.isSuccess])
  const parseAmountToNumber = (amount) => {
    const cleanedAmount = amount.trim()
    if (cleanedAmount.includes(',') && cleanedAmount.includes('.')) {
      const normalizedAmount = cleanedAmount.replace(/\./g, '').replace(',', '.')
      return parseFloat(normalizedAmount)
    } else {
      return parseFloat(cleanedAmount)
    }
  }

  const fillQuotationInformation = (quotationData, quotationEvent: DispatchEvent) => {
    const parsedData = {
      ...quotationData,
      externalId: quotationEvent.insurerOrderId,
      totalAmount: parseAmountToNumber(quotationEvent.amount),
    }

    fillQuotationInformationRequest.mutateAsync({
      brokerId: orderInformationQuery?.data?.broker?.id,
      quotationId: orderInformationQuery?.data?.quotation?.id,
      leadId: orderInformationQuery?.data?.quotation?.lead?.id,
      data: { ...parsedData },
    })
  }

  useEffect(() => {
    if (
      quotationEvent?.processingStatus === 'SUCCESS' &&
      orderInformationQuery.data?.status === 'QUOTATION_INSURER' &&
      orderInformationQuery.data?.quotation
    ) {
      const quotationData = orderInformationQuery?.data?.quotation
      if (
        quotationData.externalId !== quotationEvent.insurerOrderId ||
        quotationData.totalAmount !== parseAmountToNumber(quotationEvent.amount)
      ) {
        fillQuotationInformation(quotationData, quotationEvent)
      }
    }
  }, [quotationEvent])

  const handleOrderStatusChange = async (value: string) => {
    const { lifesQuantity, quotation } = orderInformationQuery?.data || {}

    const cameFromRegisteredInInsurer =
      orderInformationQuery?.data?.isRegisteredInInsurer &&
      !BLOCKED_STATUSES_IN_REGISTERED_IN_INSURER.includes(orderInformationQuery?.data?.status ?? '')

    if (value !== orderInformationQuery?.data?.status && !cameFromRegisteredInInsurer) {
      const allowedStatusTransitions =
        listPossibleStatusesQuery?.data?.statusTransitions.map(
          (statusTransition) => statusTransition.transitionStatus,
        ) ?? []
      const canTransitionTo = allowedStatusTransitions.some((status) => status === value)

      if (!canTransitionTo && !orderInformationQuery?.data?.isRegisteredInInsurer) {
        setOrderStatus(orderInformationQuery?.data?.status ?? null)
        triggerToast(false, `Ops, o status não pode ser alterado.`)

        return
      }

      if (value === 'FILLED') {
        const insurersRequiredFields = {
          Amil: getRequiredFieldsAmil(orderInformationQuery?.data),
          Alice: getRequiredFieldsAlice(orderInformationQuery?.data),
          'Porto Seguro': getRequiredFieldsPorto(orderInformationQuery?.data),
          GNDI: getRequiredFieldsGndi(orderInformationQuery?.data),
          'Notre Dame Intermédica': getRequiredFieldsGndi(orderInformationQuery?.data),
          Sulamérica: getRequiredFieldsSulamerica(orderInformationQuery?.data),
          'Bradesco Seguros': getRequiredFieldsBradesco(orderInformationQuery?.data),
          default: getRequiredFieldsSisweb(orderInformationQuery?.data),
        }

        let requiredFields = insurersRequiredFields.default

        if (
          !orderInformationQuery?.data?.isRegisteredInInsurer &&
          orderInformationQuery?.data?.quotation?.insurer &&
          orderInformationQuery?.data?.type !== 'PERSON'
        ) {
          requiredFields =
            insurersRequiredFields[orderInformationQuery.data.quotation.insurer] ??
            insurersRequiredFields.default
        }

        const hasEmptyValues =
          requiredFields?.some(
            (validator) => validator?.requiredFields && validator.requiredFields.length > 0,
          ) ?? false

        if (hasEmptyValues) {
          requiredFields?.forEach((validator) => {
            dynamicFormState.setErrorForRequiredField(
              validator?.dynamicFormId,
              validator?.requiredFields,
            )
          })
          setValidateOrderDispatch(true)
          triggerToast(
            false,
            'Preencha os dados obrigatórios para alterar o status. Após revisão, tente alterar o status novamente.',
          )

          return
        }
      }

      if (value === 'QUOTATION_INSURER') {
        const insurersRequiredFiedls = {
          Sulamérica: getRequiredFieldsQuotationSulamerica(orderInformationQuery?.data),
        }

        let requiredFields

        if (orderInformationQuery?.data?.quotation?.insurer) {
          requiredFields = insurersRequiredFiedls[orderInformationQuery.data.quotation.insurer]
        }
        const hasEmptyValues =
          requiredFields?.some(
            (validator) => validator?.requiredFields && validator.requiredFields.length > 0,
          ) ?? false
        if (hasEmptyValues) {
          requiredFields?.forEach((validator) => {
            dynamicFormState.setErrorForRequiredField(
              validator?.dynamicFormId,
              validator?.requiredFields,
            )
          })
          setValidateQuotationDispatch(true)
          triggerToast(
            false,
            'Preencha os dados obrigatórios para alterar o status. Após revisão, tente alterar o status novamente.',
          )

          return
        }
        if (
          lifesQuantity &&
          lifesQuantity < MIN_LIFE_QUANTITY_ALLOWED &&
          quotation?.insurer &&
          insurersQuotation.includes(quotation?.insurer)
        ) {
          setValidateQuotationDispatch(true)
          triggerToast(
            false,
            'Preencha os dados obrigatórios para alterar o status. Após revisão, tente alterar o status novamente.',
          )
          return
        }
      }
    }

    setDesiredOrderStatus(value)
    if (insurersModal.includes(value)) {
      setIsModalOpen(true)
    } else {
      setOrderStatus(value)
      await updateStatusMutation.mutateAsync({
        nextStatus: value,
        order: orderInformationQuery?.data,
      })
    }
  }

  const insurerOrderDispatch = import.meta.env.VITE_ORDER_DISPATCH_INSURER_ALLOWED
  const allowedInsurers = insurerOrderDispatch ? JSON.parse(insurerOrderDispatch) : []

  const statusMap = {
    QUOTATION_INSURER: quotationEvent,
    DISPATCH_IN_PROGRESS: implantationEvent,
  }

  const currentStatus = orderInformationQuery?.data?.status
  const currentEvent = currentStatus ? statusMap[currentStatus] : null

  useEffect(() => {
    if (orderDispatchEventQuery?.data && orderDispatchEventQuery?.data?.length > 0) {
      orderDispatchEventQuery.data.map((event) => {
        if (event.type === 'IMPLANTATION') {
          setImplantationchEvent(event)
        }
        if (event.type === 'QUOTATION') {
          setQuotationEvent(event)
          setIsUploading(true)
        }
      })
    }
  }, [orderDispatchEventQuery?.data])

  const orderStatusOptions = useMemo(() => {
    const statusMapper = statusMapperFn()

    const statusSelectOptions = Object.entries(statusMapper).map(
      ([key, { label, color, icon, disabled }]) => ({
        label: (
          <>
            {icon}
            <StyledOptionLabel>{label}</StyledOptionLabel>
          </>
        ),
        value: key,
        color,
        icon,
        disabled,
      }),
    )

    const cameFromRegisteredInInsurer =
      orderInformationQuery?.data?.isRegisteredInInsurer &&
      !BLOCKED_STATUSES_IN_REGISTERED_IN_INSURER.includes(orderInformationQuery?.data?.status ?? '')

    if (!cameFromRegisteredInInsurer) {
      return statusSelectOptions.filter(
        (item) =>
          listPossibleStatusesQuery?.data?.statusTransitions.some(
            (status) => status.transitionStatus === item.value,
          ) || item.value === orderInformationQuery?.data?.status,
      )
    }

    return statusSelectOptions
  }, [
    statusMapperFn,
    listPossibleStatusesQuery?.data?.statusTransitions,
    orderInformationQuery?.data?.status,
  ])

  const isAllowedInsurersPME =
    allowedInsurers.includes(orderInformationQuery?.data?.quotation?.insurer) &&
    orderInformationQuery?.data?.type === 'COMPANY'

  const allowedStatusToDispatch = useVariant('bliss-intranet-allowed-status-to-dispatch')?.payload
    ?.value
  const allowedStatus = allowedStatusToDispatch
    ?.split(',')
    .some((status) => orderInformationQuery?.data?.status === status)
  const isDispatchOrder =
    allowedStatus &&
    isAllowedInsurersPME &&
    !orderInformationQuery?.data?.isRegisteredInInsurer &&
    !updateStatusMutation.isPending

  const isDispatchQuotation = orderInformationQuery?.data?.status === 'QUOTATION_INSURER'
  const userMail = localStorage.getItem('userMail')
  const allowedMailsToViewScore = useVariant('bliss-intranet-allowed-users-score')?.payload?.value
  const isUserAllowedToViewScore = userMail && allowedMailsToViewScore?.includes(userMail)

  const handleAddQueryParam = (tabKey: string) => {
    const newQueryParam = `tab=${tabKey}`
    setSearchParams(newQueryParam)
  }

  const items = [
    {
      key: 'order',
      label: <LabelTabsItem>Proposta</LabelTabsItem>,
      children: (
        <ProposalTab
          orderId={orderId}
          validateOrderDispatch={validateOrderDispatch ?? false}
          validateQuotationDispatch={validateQuotationDispatch ?? false}
          validateSiswebDispatch={validateSiswebDispatch ?? false}
          setValidateSiswebDispatch={setValidateSiswebDispatch}
          orderInformationQuery={orderInformationQuery}
          setFillIsRegisteredInInternalSystemRequestConfig={
            setFillIsRegisteredInInternalSystemRequestConfig
          }
          isRegisteredInInternalSystem={isRegisteredInInternalSystem ?? false}
          setIsRegisteredInInternalSystem={setIsRegisteredInInternalSystem}
          isLoadingFillIsRegisteredInInternalSystem={isLoadingFillIsRegisteredInInternalSystem}
        />
      ),
    },
    {
      key: 'timeline',
      label: <LabelTabsItem>Linha do tempo</LabelTabsItem>,
      children: (
        <TimeLineTab
          orderId={orderInformationQuery?.data?.id}
          shouldReloadTimelineRequest={shouldReloadTimelineRequest}
          setShouldFillResponsibleAdminsIds={setShouldFillResponsibleAdminsIds}
          setShouldReloadTimelineRequest={setShouldReloadTimelineRequest}
          viewContentResponsibleAdmin={false}
          planCard={
            <PlanCardComponent
              setShouldReload={setShouldReload}
              setFillIsRegisteredInInternalSystemRequestConfig={
                setFillIsRegisteredInInternalSystemRequestConfig
              }
              isRegisteredInInternalSystem={isRegisteredInInternalSystem}
              setIsRegisteredInInternalSystem={setIsRegisteredInInternalSystem}
              isLoadingFillIsRegisteredInInternalSystem={isLoadingFillIsRegisteredInInternalSystem}
              orderInformationQuery={orderInformationQuery}
            />
          }
        />
      ),
    },
    {
      key: 'activity',
      label: <LabelTabsItem>Atividades</LabelTabsItem>,
      children: (
        <ActivityTab
          orderId={orderInformationQuery?.data?.id}
          planCard={
            <PlanCardComponent
              setShouldReload={setShouldReload}
              setFillIsRegisteredInInternalSystemRequestConfig={
                setFillIsRegisteredInInternalSystemRequestConfig
              }
              isRegisteredInInternalSystem={isRegisteredInInternalSystem}
              setIsRegisteredInInternalSystem={setIsRegisteredInInternalSystem}
              isLoadingFillIsRegisteredInInternalSystem={isLoadingFillIsRegisteredInInternalSystem}
              orderInformationQuery={orderInformationQuery}
            />
          }
        />
      ),
    },
    {
      key: 'lead',
      label: <LabelTabsItem>Dados do Lead</LabelTabsItem>,
      children: (
        <LeadDataTab
          lead={orderInformationQuery?.data?.quotation?.lead}
          brokerId={orderInformationQuery?.data?.broker?.id}
          planCard={
            <PlanCardComponent
              setShouldReload={setShouldReload}
              setFillIsRegisteredInInternalSystemRequestConfig={
                setFillIsRegisteredInInternalSystemRequestConfig
              }
              isRegisteredInInternalSystem={isRegisteredInInternalSystem}
              setIsRegisteredInInternalSystem={setIsRegisteredInInternalSystem}
              isLoadingFillIsRegisteredInInternalSystem={isLoadingFillIsRegisteredInInternalSystem}
              orderInformationQuery={orderInformationQuery}
            />
          }
        />
      ),
    },
    {
      key: 'responsible',
      label: <LabelTabsItem>{`Responsáveis (${responsibleAdminsQuantity})`}</LabelTabsItem>,
      children: (
        <ResponsibleAdminTab
          orderData={orderInformationQuery?.data}
          isLoadingGetOrderById={orderInformationQuery.isPending}
          setResponsibleAdminsQuantity={setResponsibleAdminsQuantity}
          planCard={
            <PlanCardComponent
              setShouldReload={setShouldReload}
              setFillIsRegisteredInInternalSystemRequestConfig={
                setFillIsRegisteredInInternalSystemRequestConfig
              }
              isRegisteredInInternalSystem={isRegisteredInInternalSystem}
              setIsRegisteredInInternalSystem={setIsRegisteredInInternalSystem}
              orderInformationQuery={orderInformationQuery}
            />
          }
        />
      ),
    },
    {
      key: 'create-order',
      label: (
        <div style={{ width: '100%', flex: 1 }}>
          <Button
            style={{ height: '30px', width: '100%', gap: 0 }}
            onClick={() => navigate('/cadastrar-proposta')}
          >
            <PlusOutlined /> <p>Cadastrar nova proposta</p>
          </Button>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (
      orderInformationQuery.isRefetching === false &&
      isValidatingForms &&
      allowedInsurers.includes(orderInformationQuery?.data?.quotation?.insurer)
    ) {
      handleOrderStatusChange('DISPATCH_IN_PROGRESS')
      setIsValidatingForms(false)
    }
  }, [orderInformationQuery?.isRefetching])

  const verifyForm = () => {
    orderInformationQuery.refetch()
    if (isInsurerQuotation && !orderInformationQuery?.data?.quotation?.externalId) {
      setValidateOrderDispatch(true)
    } else {
      setIsValidatingForms(true)
    }
  }

  return (
    <OrderDiv>
      <OrderHeader>
        {orderInformationQuery?.data == null && orderDispatchEventQuery ? (
          <OrderHeaderContent>
            <HorizontalOrderTags>
              <Skeleton type="input" />
              <Skeleton type="input" />
            </HorizontalOrderTags>
            <OrderStatusSelectLoadingDiv>
              <Skeleton type="input" />
            </OrderStatusSelectLoadingDiv>
          </OrderHeaderContent>
        ) : (
          <OrderHeaderContent>
            <OrderHeaderInfosContainer>
              {isUserAllowedToViewScore ? (
                <OrderScoreTag score={orderInformationQuery?.data?.score} label="Score:" />
              ) : null}
              <OrderTagsDiv>
                <div>
                  <HorizontalOrderTags>
                    <OrderTag>
                      <span>Corretor: </span>
                      {orderInformationQuery?.data && orderInformationQuery?.data?.broker?.name}
                    </OrderTag>
                    <OrderTag>
                      <span>Lead:</span>
                      {orderInformationQuery?.data &&
                        (orderInformationQuery?.data?.quotation?.lead?.name ||
                          orderInformationQuery?.data?.lead?.name)}
                    </OrderTag>
                  </HorizontalOrderTags>
                  <OrderTag>
                    <span>Id Intranet:</span>
                    {orderInformationQuery?.data && orderInformationQuery?.data?.id}
                  </OrderTag>
                </div>

                {currentEvent && (
                  <DispatchInfos
                    orderData={orderInformationQuery?.data}
                    dispatchEvent={currentEvent}
                  />
                )}
              </OrderTagsDiv>
            </OrderHeaderInfosContainer>

            {orderDispatchEventQuery.isLoading || orderInformationQuery.isPending ? (
              <StyledSkeletonDiv>
                <Skeleton type="input" />
              </StyledSkeletonDiv>
            ) : (
              <OrderStatusSelect>
                {isDispatchOrder && (
                  <RequestTag onClick={() => verifyForm()}>
                    {isValidatingForms ? (
                      <Spinner width="12px" height="12px" />
                    ) : (
                      <SettingOutlined />
                    )}
                    <span>Solicitar emissão automática</span>
                  </RequestTag>
                )}
                {isDispatchQuotation && (
                  <RequestTag
                    onClick={() => {
                      setDesiredOrderStatus('QUOTATION_INSURER')
                      setIsModalOpen(true)
                    }}
                  >
                    {isValidatingForms ? (
                      <Spinner width="12px" height="12px" />
                    ) : (
                      <SettingOutlined />
                    )}
                    <span>Nova Cotação</span>
                  </RequestTag>
                )}

                <Select
                  options={orderStatusOptions}
                  disabled={orderStatusOptions.length === 1 || updateStatusMutation.isPending}
                  value={orderStatus ?? undefined}
                  onChange={(value) => {
                    if (typeof value === 'string') {
                      handleOrderStatusChange(value)
                    }
                  }}
                />
              </OrderStatusSelect>
            )}
          </OrderHeaderContent>
        )}
      </OrderHeader>

      {orderInformationQuery?.data != null ? (
        <MainContent>
          <Tabs
            defaultActiveKey={tab ?? undefined}
            onTabClick={(key) => handleAddQueryParam(key)}
            items={items}
            itemsColor="#514984"
          />
        </MainContent>
      ) : (
        <>
          <ContentTabSkeleton>
            <Skeleton type="input" />
            <Skeleton type="default" paragraph={{ rows: 8 }} />
          </ContentTabSkeleton>
        </>
      )}
      <Modal
        isOpen={isModalOpen}
        width={800}
        setIsOpen={() => setIsModalOpen(false)}
        footer={false}
      >
        {desiredOrderStatus === 'BILL_PAYMENT_PENDING' && (
          <BillPendingModal
            setIsModalOpen={setIsModalOpen}
            orderId={orderId}
            setOrderStatus={setOrderStatus}
          />
        )}
        {desiredOrderStatus === 'FILLING_NOT_MEET_REQUIREMENTS' && (
          <UpdateStatusDescriptionModal
            orderId={orderId}
            setIsModalOpen={setIsModalOpen}
            setOrderStatus={setOrderStatus}
          />
        )}
        {desiredOrderStatus === 'RISK_ANALYSIS' && (
          <RiskAnalysisModal
            orderId={orderId}
            setIsModalOpen={setIsModalOpen}
            setOrderStatus={setOrderStatus}
          />
        )}
        {desiredOrderStatus === 'CANCELED' && (
          <CancelModal
            orderId={orderId}
            setIsModalOpen={setIsModalOpen}
            setOrderStatus={setOrderStatus}
          />
        )}
        {desiredOrderStatus === 'ANALYSIS' && (
          <AnalysisModal
            orderId={orderId}
            insurerId={orderInformationQuery?.data?.insurerId}
            setIsModalOpen={setIsModalOpen}
            setOrderStatus={setOrderStatus}
            setShouldReload={setShouldReload}
          />
        )}
        {desiredOrderStatus === 'QUOTATION_INSURER' && (
          <QuotationInsurerModal
            insurer={orderInformationQuery?.data?.quotation?.insurer}
            orderId={orderId}
            setIsModalOpen={setIsModalOpen}
            setOrderStatus={setOrderStatus}
          />
        )}
        {desiredOrderStatus === 'DISPATCH_IN_PROGRESS' && (
          <OrderDispatchingModal
            orderId={orderId}
            insurer={orderInformationQuery?.data?.quotation?.insurer}
            setIsModalOpen={setIsModalOpen}
            setOrderStatus={setOrderStatus}
            reloadOrderData={orderInformationQuery.refetch}
          />
        )}
      </Modal>
      {desiredOrderStatus === 'WAITING_SIGNATURE' && (
        <Modal
          width={800}
          isOpen={isModalOpen}
          setIsOpen={() => setIsModalOpen(false)}
          footer={false}
        >
          <WaitingSignatureModal
            orderId={orderId}
            setIsModalOpen={setIsModalOpen}
            setShouldFillResponsibleAdminsIds={setShouldFillResponsibleAdminsIds}
            setOrderStatus={setOrderStatus}
          />
        </Modal>
      )}
      <Toast position="top-right" />
    </OrderDiv>
  )
}
