import { ReactNode, useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { colors } from '@design-system/styles/theme'
import dayjs from 'dayjs'
import RadioGroup from 'design-system/Radio/Radio'
import { Button, Mentions, Skeleton } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'
import { v4 as uuid } from 'uuid'

import { healthPlansOrderRequest } from '@/App/clients/healthPlansOrder'
import {
  CreateOrderNoteDto,
  GetAdminsByIdDto,
  GetOrderNotesDto,
  OrderNote,
} from '@/App/clients/healthPlansOrder/dto'
import useFetch from '@/App/clients/http'
import { UserContext } from '@/App/contexts/userData/userContext'

import {
  CancelButton,
  ListContentNote,
  ListNotesContainer,
  ListNotesDate,
  ListNotesHeader,
  ListNotesTitle,
  LoadMorButtonContainer,
  NotationAction,
  NotationType,
  NoteTypeTag,
  NotesButtons,
  NotesContainer,
  StyledConfirmButton,
  StyledFormContatainer,
  StyledMention,
  StyledNotesMainContainer,
} from './style'

interface INotesProps {
  orderId?: string | null
  planCard: ReactNode
}

export const Notes = ({ orderId, planCard }: INotesProps) => {
  const [note, setNote] = useState('')
  const [admins, setAdmins] = useState<Array<{ id: string; display: string }>>([])
  const [orderNoteBody, setOrderNoteBody] = useState<CreateOrderNoteDto | null>(null)
  const [orderNotes, setOrderNotes] = useState<Array<OrderNote>>([])
  const [page, setPage] = useState(0)
  const [hasNext, setHasNext] = useState(false)

  const getAdminsByIdRequestConfig = healthPlansOrderRequest.getAdminsByFilters()
  const getOrderNotesRequestConfig = healthPlansOrderRequest.getOrderNotesById(orderId, page)
  const createOrderNoteRequestConfig = healthPlansOrderRequest.createOrderNote(
    orderId,
    orderNoteBody,
  )

  const { setRequestConfig: getAdmins, data: adminsData } = useFetch<GetAdminsByIdDto>()

  const {
    setRequestConfig: getOrderNotesById,
    data: orderNotesData,
    isLoading: orderNotesLoading,
    error: orderNotesError,
  } = useFetch<GetOrderNotesDto>()

  const {
    setRequestConfig: createOrderNote,
    data: createOrderNotesData,
    isLoading: createOrderNotesLoading,
    error: createOrderNotesError,
  } = useFetch<CreateOrderNoteDto>()

  useEffect(() => {
    getAdmins(getAdminsByIdRequestConfig)
  }, [])

  useEffect(() => {
    const transformedAdmins = adminsData?.admins.map((admin) => ({
      id: admin.id,
      display: admin.name,
    }))
    setAdmins(transformedAdmins ?? [])
  }, [adminsData])

  useEffect(() => {
    getOrderNotesById(getOrderNotesRequestConfig)
  }, [page])

  useEffect(() => {
    if (orderNotesData == null) return

    setHasNext(orderNotesData?.hasNext)

    setOrderNotes([...orderNotes, ...orderNotesData.orderNotes])
  }, [orderNotesData])

  useEffect(() => {
    if (orderNoteBody) {
      createOrderNote(createOrderNoteRequestConfig)
    }
  }, [orderNoteBody])

  useEffect(() => {
    if (createOrderNotesData) {
      setOrderNoteBody(null)
      setNote('')
    }
  }, [createOrderNotesData])

  useEffect(() => {
    let errorMessage: string | null = null

    if (orderNotesError) {
      errorMessage = 'Ops, ocorreu um erro ao carregar as anotações'
    } else if (createOrderNotesError) {
      errorMessage = 'Ops, ocorreu um erro ao criar as anotações'
    }

    if (errorMessage) {
      triggerToast(false, errorMessage)
    }
  }, [orderNotesError, createOrderNotesError])

  const userContext = useContext(UserContext)

  const NoteContent = ({ inputString }: { inputString: string }) => {
    const mentionPattern = /<@([^>]+)>/g
    const mentionMatch = inputString.match(mentionPattern)

    if (!mentionMatch) {
      return <div>{inputString}</div>
    }

    const parts = inputString.split(/<([^>]+)>/g)
    return (
      <>
        {parts.map((mention: string, index: number) =>
          mention.includes('@') ? <StyledMention key={index}>{mention}</StyledMention> : mention,
        )}
      </>
    )
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
    delayError: 1000,
  })

  type FormData = {
    type: string
  }

  const handleConfirmClick = (type: string) => {
    const stringBetweenBrackets = /@\[([^\]]+)\]\(([^)]+)\)/g
    const content = note.replace(stringBetweenBrackets, '@$2').replace(/\n/g, ' ')
    const mentionMessage = note.replace(stringBetweenBrackets, '<@$1>').replace(/\n/g, ' ')

    const creatorName = userContext?.user?.name
    setOrderNoteBody({
      type,
      content,
    })
    setOrderNotes((prev) => [
      { type, mentionMessage, creatorName, id: uuid() } as OrderNote,
      ...prev,
    ])
  }

  const onSubmit: SubmitHandler<FormData> = (data) => {
    handleConfirmClick(data.type)
  }

  if (orderNotesLoading) {
    return <Skeleton />
  }

  return (
    <StyledNotesMainContainer>
      <StyledFormContatainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <NotesContainer>
            <Mentions
              key={1}
              value={note}
              onChange={(e) => {
                setNote(e.target.value)
              }}
              data={admins}
            />
            <NotesButtons>
              <NotationType>
                <RadioGroup
                  options={[
                    { label: 'Anotação externa', value: 'PUBLIC' },
                    { label: 'Anotação interna', value: 'INTERNAL' },
                  ]}
                  register={{
                    ...register('type', {
                      required: 'Você precisa selecionar uma das opções.',
                    }),
                  }}
                  error={errors.type}
                  defaultValue="INTERNAL"
                />
              </NotationType>
              <NotationAction>
                <CancelButton onClick={() => ''} type="button">
                  Cancelar
                </CancelButton>
                <StyledConfirmButton
                  loading={createOrderNotesLoading}
                  height="32px"
                  backgroundColor={colors.darkPurple}
                  htmlType="submit"
                  disabled={!note}
                >
                  Salvar
                </StyledConfirmButton>
              </NotationAction>
            </NotesButtons>
          </NotesContainer>
        </form>
        {orderNotes?.map((item) => (
          <ListNotesContainer key={item.id}>
            <ListNotesHeader>
              <ListNotesTitle>{item.creatorName}</ListNotesTitle>
              <ListNotesDate>
                {dayjs(item.createdAt).locale('pt-br').format('DD/MM/YYYY [às] HH:mm')}
              </ListNotesDate>
              <NoteTypeTag>
                {item.type === 'INTERNAL' ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                {item.type === 'INTERNAL' ? 'Anotação interna' : 'Anotação externa'}
              </NoteTypeTag>
            </ListNotesHeader>
            <ListContentNote>
              <NoteContent inputString={item.content || item.mentionMessage} />
            </ListContentNote>
          </ListNotesContainer>
        ))}
        {hasNext && (
          <LoadMorButtonContainer>
            <Button onClick={() => setPage(page + 1)}>Carregar mais...</Button>
          </LoadMorButtonContainer>
        )}
      </StyledFormContatainer>
      <div>{planCard}</div>
    </StyledNotesMainContainer>
  )
}
