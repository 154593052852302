import { QueryFunctionContext, queryOptions, useQuery } from '@tanstack/react-query'

import { api } from '../../http'
import { FiltersBrokersDTO } from '../dtos'

type FilterOption = {
  label?: string
  value?: string
}

const getFiltersBrokers = async ({ signal }: QueryFunctionContext) => {
  return api
    .get<FiltersBrokersDTO>(
      `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/filter/brokers`,
      {
        signal,
        headers: { Authorization: localStorage.getItem('authToken') },
      },
    )
    .then((res) => res.data)
}

export function getFiltersBrokersQueryOptions() {
  return queryOptions({
    queryKey: ['filter', 'brokers'] as const,
    queryFn: async (params) => getFiltersBrokers(params),
    select: (data) => {
      const brokersOptions: Array<FilterOption> = []
      const internalSystemIdsOptions: Array<FilterOption> = []

      for (const brokerInfo of data.brokers) {
        brokersOptions.push({
          label: brokerInfo.name,
          value: brokerInfo.id,
        })

        if (brokerInfo.internalSystemId !== null) {
          internalSystemIdsOptions.push({
            label: brokerInfo.internalSystemId,
            value: brokerInfo.internalSystemId,
          })
        }
      }

      return {
        brokersOptions: brokersOptions.sort((a, b) =>
          (a.label ?? '')?.localeCompare(b.label ?? '') ? 1 : -1,
        ),
        internalSystemIdsOptions: internalSystemIdsOptions.sort((a, b) => {
          const aLabel = Number(a.label) || 0
          const bLabel = Number(b.label) || 0
          return aLabel >= bLabel ? 1 : 0
        }),
      }
    },
  })
}

export const useFiltersBrokersQuery = () => {
  return useQuery(getFiltersBrokersQueryOptions())
}
