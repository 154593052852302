import { useMutation, useQueryClient } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '../../http'

type UpdateOrderBrokerBaseRequest = {
  brokerId?: string | null
  leadId?: string | null
  quotationId?: string | null
  orderId?: string | null
}

async function updateOrderBroker({
  brokerId,
  leadId,
  quotationId,
  orderId,
}: UpdateOrderBrokerBaseRequest) {
  await api.patch(
    `${import.meta.env.VITE_BLISS_ORDER_GATEWAY_API_URL}/orders/${orderId}/broker-change`,
    {
      brokerId,
      leadId,
      quotationId,
    },
    {
      headers: {
        Authorization: localStorage.getItem('authToken'),
      },
    },
  )
}

export const useUpdateOrderBroker = (orderId?: string | null) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['orderBroker', orderId] as const,
    mutationFn: updateOrderBroker,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['orderBroker'] })
    },
    onError: () => {
      triggerToast(false, 'Erro ao realizar alteração')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['orderBroker'],
      })

      triggerToast(true, 'Alteração realizada com sucesso')
    },
  })
}
