import { useEffect, useMemo, useRef, useState } from 'react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ArrowRightOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons'
import { Modal, Select, Skeleton, SwitchButton, Tabs, Toast } from 'design-system/components'
import DentalIcon from 'design-system/dental'
import HealthIcon from 'design-system/health'
import PetIcon from 'design-system/pet'
import { triggerToast } from 'design-system/triggers'

import { healthPlansOrderRequest } from '@/App/clients/healthPlansOrder'
import { OrdersData, RecordDto } from '@/App/clients/healthPlansOrder/dto'
import { FillLeadInformationDto } from '@/App/clients/healthPlansOrder/dto'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import useFetch from '@/App/clients/http'
import { useUpdateStatus } from '@/App/clients/orderState/mutation/updateStatus'
import { usePossibleStatusesQuery } from '@/App/clients/orderState/queries/listPossibleStates'
import { AnalysisModal } from '@/App/components/Modal/OrderStatus/Analysis'
import { BillPendingModal } from '@/App/components/Modal/OrderStatus/BillPaymentPending'
import { CancelModal } from '@/App/components/Modal/OrderStatus/CanceledOrder'
import { QuotationInsurerModal } from '@/App/components/Modal/OrderStatus/QuotationInsurer'
import { RiskAnalysisModal } from '@/App/components/Modal/OrderStatus/RiskAnalysis'
import { UpdateStatusDescriptionModal } from '@/App/components/Modal/OrderStatus/UpdateStatusDescription'
import { WaitingSignatureModal } from '@/App/components/Modal/OrderStatus/WaitingSignature'
import { ActivityTab } from '@/App/components/orderTabs/ActivitiesTab/ActivityTab'
import { FilesTab } from '@/App/components/orderTabs/FilesTab/FilesTab'
import { LeadDataTab } from '@/App/components/orderTabs/LeadDataTab/LeadDataTab'
import { TimeLineTab } from '@/App/components/orderTabs/TimeLineTab/timeLineTab'
import { useDynamicForm } from '@/App/contexts/dynamicForm/DynamicFormProvider'
import { BLOCKED_STATUSES_IN_REGISTERED_IN_INSURER } from '@/App/helpers/constants'
import { statusMapperFn } from '@/App/helpers/statusMapper'

import { getRequiredFieldsAlice } from '../../orderDetails/entities/entityAccordion/helper/alice/requiredFields'
import { getRequiredFieldsAmil } from '../../orderDetails/entities/entityAccordion/helper/amil/requiredFields'
import { getRequiredFieldsBradesco } from '../../orderDetails/entities/entityAccordion/helper/bradesco/requiredFields'
import { getRequiredFieldsGndi } from '../../orderDetails/entities/entityAccordion/helper/gndi/requiredFields'
import { getRequiredFieldsPorto } from '../../orderDetails/entities/entityAccordion/helper/porto/requiredFields'
import { getRequiredFieldsSisweb } from '../../orderDetails/entities/entityAccordion/helper/sisweb/requiredFields'
import { getRequiredFieldsSulamerica } from '../../orderDetails/entities/entityAccordion/helper/sulamerica/requiredFields'
import {
  ActionButton,
  EditableButton,
  EditableTextInput,
  EditableTextInputWrapper,
  ExpandableRowContainer,
  Footer,
  IconsDiv,
  MainContent,
  PlanCard,
  Sidebar,
  StyledBrokerName,
  StyledLi,
  StyledOptionLabel,
  StyledSpan,
  StyledSpanLi,
  StyledUl,
} from './style'

interface IContentProps {
  record: RecordDto
  setOrders?: React.Dispatch<React.SetStateAction<OrdersData[]>>
}

export const ExpandableRowContent: React.FC<IContentProps> = ({ record, setOrders }) => {
  const [orderStatus, setOrderStatus] = useState<string | null>(null)
  const [isEditable, setIsEditable] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [insurerIdInputValue, setInsurerIdInputValue] = useState<string | null>(null)
  const [shouldReloadTimelineRequest, setShouldReloadTimelineRequest] = useState(false)
  const [shouldFillResponsibleAdminsIds, setShouldFillResponsibleAdminsIds] = useState(false)
  const [desiredOrderStatus, setDesiredOrderStatus] = useState<string>()

  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)
  const orderInformationQuery = useOrderInformationQuery({ orderId: record.key })
  const listPossibleStatusesQuery = usePossibleStatusesQuery(orderStatus)
  const updateStatusMutation = useUpdateStatus(record.key)
  const dynamicFormState = useDynamicForm()

  const insurersModal = [
    'BILL_PAYMENT_PENDING',
    'FILLING_NOT_MEET_REQUIREMENTS',
    'CANCELED',
    'WAITING_SIGNATURE',
    'ANALYSIS',
    'RISK_ANALYSIS',
    'QUOTATION_INSURER',
  ]
  const {
    setRequestConfig: setIsRegisteredInternalSystemRequest,
    isLoading: isLoadingIsRegisteredInternalSystem,
    error: errorIsRegisteredInternalSystem,
    statusCode: registeredInternalSystemStatusCode,
  } = useFetch<FillLeadInformationDto>()
  const {
    setRequestConfig: setInsurerIdRequest,
    isLoading: isLoadingInsurerId,
    statusCode: insurerIdStatusCode,
    error: errorInsurerId,
  } = useFetch<FillLeadInformationDto>()

  const sendInsurerId = (data: { insurerId?: string | null }) => {
    const fillLeadInformationConfig = healthPlansOrderRequest.fillOrderInformation(record.key, data)
    setInsurerIdRequest(fillLeadInformationConfig)
  }

  const sendIsRegisteredInternalSystem = (data: { isRegisteredInInternalSystem: boolean }) => {
    const fillLeadInformationConfig = healthPlansOrderRequest.fillOrderInformation(record.key, data)
    setIsRegisteredInternalSystemRequest(fillLeadInformationConfig)
  }

  useEffect(() => {
    if (insurerIdInputValue) setInsurerIdInputValue(insurerIdInputValue)
    if (insurerIdInputValue != orderInformationQuery.data?.insurerId)
      setInsurerIdInputValue(orderInformationQuery.data?.insurerId ?? null)
  }, [insurerIdInputValue, orderInformationQuery.data?.insurerId])

  useEffect(() => {
    if (isEditable && inputRef.current) {
      inputRef.current?.focus()
    }
  }, [isEditable])

  useEffect(() => {
    if (record) setLeadValues(record)
  }, [])

  useEffect(() => {
    if (updateStatusMutation.isSuccess || orderStatus != orderInformationQuery.data?.status) {
      updateOrderStatus(record.key, orderStatus)
      setShouldReloadTimelineRequest(true)
    } else if (updateStatusMutation.isError) {
      updateOrderStatus(record.key, orderStatus ?? '')
    }
  }, [orderStatus, updateStatusMutation.isSuccess, updateStatusMutation.isError])

  useEffect(() => {
    const errorReference = updateStatusMutation.error?.message
      ? 'ao atualizar o status'
      : errorInsurerId
      ? 'ao atualizar o id do sisweb'
      : 'ao atualizar o o status'

    if (updateStatusMutation.isError || errorInsurerId || errorIsRegisteredInternalSystem) {
      triggerToast(false, `Ops, ocorreu um erro ${errorReference}`)
    }
  }, [
    updateStatusMutation.isError,
    updateStatusMutation.error?.message,
    errorInsurerId,
    errorIsRegisteredInternalSystem,
  ])

  useEffect(() => {
    if (registeredInternalSystemStatusCode === 200)
      triggerToast(true, 'Valor de cadastro no sisweb atualizado com sucesso!')
    else if (registeredInternalSystemStatusCode && registeredInternalSystemStatusCode > 400)
      triggerToast(false, 'Ops, ocorreu um erro ao atualizar o valor do cadastro no sisweb')
  }, [registeredInternalSystemStatusCode])

  useEffect(() => {
    if (insurerIdStatusCode === 200) {
      setIsEditable(false)
      triggerToast(true, 'Nº da proposta na operadora atualizado com sucesso!')
    } else if (insurerIdStatusCode && insurerIdStatusCode > 400)
      triggerToast(false, 'Ops, ocorreu um erro ao atualizar o nº da proposta na operadora')
  }, [insurerIdStatusCode])

  useEffect(() => {
    if (orderInformationQuery.isSuccess) {
      setInsurerIdInputValue(orderInformationQuery.data.insurerId ?? null)
      setOrderStatus(orderInformationQuery.data.status ?? null)
    }
  }, [orderInformationQuery.isSuccess, orderInformationQuery.data])

  const items = [
    {
      key: '1',
      label: 'Proposta',
      children: (
        <TimeLineTab
          orderId={record.key}
          shouldReloadTimelineRequest={shouldReloadTimelineRequest}
          setShouldReloadTimelineRequest={setShouldReloadTimelineRequest}
          setShouldFillResponsibleAdminsIds={setShouldFillResponsibleAdminsIds}
          shouldFillResponsibleAdminsIds={shouldFillResponsibleAdminsIds}
          viewContentResponsibleAdmin={true}
        />
      ),
    },
    {
      key: '2',
      label: 'Atividades',
      children: <ActivityTab orderId={record.key} />,
    },
    {
      key: '3',
      label: 'Anexos',
      children: <FilesTab orderId={record.key} />,
    },
    {
      key: '4',
      label: 'Dados do Lead',
      children: (
        <LeadDataTab
          brokerId={orderInformationQuery.data?.broker?.id}
          lead={orderInformationQuery.data?.lead}
        />
      ),
    },
  ]

  const orderStatusOptions = useMemo(() => {
    const statusMapper = statusMapperFn()

    const statusSelectOptions = Object.entries(statusMapper).map(
      ([key, { label, color, icon, disabled }]) => ({
        label: (
          <>
            {icon}
            <StyledOptionLabel>{label}</StyledOptionLabel>
          </>
        ),
        value: key,
        color,
        icon,
        disabled,
      }),
    )

    const cameFromRegisteredInInsurer =
      orderInformationQuery?.data?.isRegisteredInInsurer &&
      !BLOCKED_STATUSES_IN_REGISTERED_IN_INSURER.includes(orderStatus ?? '')

    if (!cameFromRegisteredInInsurer) {
      return statusSelectOptions.filter(
        (item) =>
          listPossibleStatusesQuery?.data?.statusTransitions.some(
            (status) => status.transitionStatus === item.value,
          ) || item.value === orderStatus,
      )
    }

    return statusSelectOptions
  }, [statusMapperFn, listPossibleStatusesQuery?.data?.statusTransitions, orderStatus])

  const getQuotationIcon = (quotationProduct: string) => {
    switch (quotationProduct) {
      case 'HEALTH':
        return <HealthIcon />
      case 'DENTAL':
        return <DentalIcon />
      case 'PET':
        return <PetIcon />
      case 'HEALTH_AND_DENTAL':
        return (
          <IconsDiv>
            <HealthIcon />
            <DentalIcon />
          </IconsDiv>
        )
    }
  }

  const setLeadValues = (record: RecordDto) => {
    const lead = record.lead || (record.quotation && record.quotation.lead)
    if (lead) {
      for (const key in lead) {
        if (lead[key] !== null) {
          record[key] = lead[key]
        }
      }
    }
  }

  const handleChange = async (value: string) => {
    const cameFromRegisteredInInsurer =
      orderInformationQuery?.data?.isRegisteredInInsurer &&
      !BLOCKED_STATUSES_IN_REGISTERED_IN_INSURER.includes(orderInformationQuery?.data?.status ?? '')

    if (!cameFromRegisteredInInsurer) {
      const allowedStatusTransitions =
        listPossibleStatusesQuery?.data?.statusTransitions.map(
          (statusTransition) => statusTransition.transitionStatus,
        ) ?? []
      const canTransitionTo = allowedStatusTransitions.some((status) => status === value)

      if (!canTransitionTo && !orderInformationQuery?.data?.isRegisteredInInsurer) {
        setOrderStatus(orderInformationQuery.data?.status ?? null)
        triggerToast(false, `Ops, o status não pode ser alterado.`)

        return
      }

      if (value === 'FILLED') {
        const insurersRequiredFields = {
          Amil: getRequiredFieldsAmil(orderInformationQuery?.data),
          Alice: getRequiredFieldsAlice(orderInformationQuery?.data),
          'Porto Seguro': getRequiredFieldsPorto(orderInformationQuery?.data),
          GNDI: getRequiredFieldsGndi(orderInformationQuery?.data),
          'Notre Dame Intermédica': getRequiredFieldsGndi(orderInformationQuery?.data),
          Sulamérica: getRequiredFieldsSulamerica(orderInformationQuery?.data),
          'Bradesco Seguros': getRequiredFieldsBradesco(orderInformationQuery?.data),
          default: getRequiredFieldsSisweb(orderInformationQuery?.data),
        }

        let requiredFields = insurersRequiredFields.default

        if (
          !orderInformationQuery?.data?.isRegisteredInInsurer &&
          orderInformationQuery?.data?.quotation?.insurer &&
          orderInformationQuery?.data?.type !== 'PERSON'
        ) {
          requiredFields =
            insurersRequiredFields[orderInformationQuery.data.quotation.insurer] ??
            insurersRequiredFields.default
        }

        const hasEmptyValues =
          requiredFields?.some(
            (validator) => validator?.requiredFields && validator.requiredFields.length > 0,
          ) ?? false

        if (hasEmptyValues) {
          requiredFields?.forEach((validator) => {
            dynamicFormState.setErrorForRequiredField(
              validator?.dynamicFormId,
              validator?.requiredFields,
            )
          })
          triggerToast(
            false,
            'Preencha os dados obrigatórios para alterar o status. Após revisão, tente alterar o status novamente.',
          )

          return
        }
      }
    }

    setDesiredOrderStatus(value)
    if (insurersModal.includes(value)) {
      setIsModalOpen(true)
    } else {
      setOrderStatus(value)
      setIsModalOpen(false)
      await updateStatusMutation.mutateAsync({
        nextStatus: value,
        order: orderInformationQuery.data,
      })
    }
    updateOrderStatus(record.key, orderStatus)
  }

  const updateOrderStatus = (orderId: string, updatedOrderStatus?: string | null) => {
    if (setOrders) {
      setOrders((prevOrders) => {
        const updatedOrders = prevOrders.map((order) => {
          if (order.key === orderId) {
            return { ...order, status: updatedOrderStatus }
          }
          return order
        })

        return updatedOrders
      })
    }
  }

  return (
    <ExpandableRowContainer>
      <MainContent>
        <Tabs items={items} />
      </MainContent>
      <Sidebar>
        {orderInformationQuery.isLoading ? (
          <Skeleton active type="button" />
        ) : (
          <Select
            value={orderStatus ?? undefined}
            options={orderStatusOptions}
            disabled={orderStatusOptions.length === 1 || updateStatusMutation.isPending}
            onChange={(value) => {
              if (typeof value === 'string') {
                handleChange(value)
              }
            }}
          />
        )}

        <PlanCard>
          {getQuotationIcon(record.quotation?.product)}
          <StyledUl>
            <StyledLi>{record.quotation?.insurer ?? 'Sem operadora preenchida'}</StyledLi>
            <StyledLi>
              {record.lifesQuantity ?? 0} vida
              {record.lifesQuantity > 1 || record.lifesQuantity == null ? 's' : ''}
            </StyledLi>
            <StyledLi>
              Valor total:
              <StyledSpanLi>
                {record.quotation?.totalAmount != null
                  ? record.quotation?.totalAmount.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : record.totalAmount != null
                  ? record.totalAmount.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : ' - '}
              </StyledSpanLi>
            </StyledLi>
          </StyledUl>
          <ActionButton onClick={() => navigate(`/propostas/${record.key}/?tab=order`)}>
            Abrir Proposta <ArrowRightOutlined />
          </ActionButton>
        </PlanCard>
        <Footer>
          <StyledSpan>
            Cadastrada no Sisweb:
            <SwitchButton
              isLoading={isLoadingIsRegisteredInternalSystem}
              defaultChecked={record.isRegisteredInInternalSystem}
              onChange={(checked: boolean) =>
                sendIsRegisteredInternalSystem({ isRegisteredInInternalSystem: checked })
              }
            />
          </StyledSpan>
          {isLoadingInsurerId ? (
            <Skeleton active type="button" />
          ) : (
            <StyledSpan>
              Nº da proposta na operadora:
              <EditableTextInputWrapper>
                <EditableTextInput
                  type="text"
                  value={insurerIdInputValue ?? ''}
                  ref={(input) => input && input.focus()}
                  onChange={(e) =>
                    setInsurerIdInputValue(e.target.value.replaceAll(/[^A-Za-z0-9_-]/g, ''))
                  }
                  disabled={isEditable}
                />
                <EditableButton onClick={() => setIsEditable(!isEditable)}>
                  {isEditable ? (
                    <EditOutlined />
                  ) : (
                    <CheckOutlined
                      onClick={() => sendInsurerId({ insurerId: insurerIdInputValue })}
                    />
                  )}
                </EditableButton>
              </EditableTextInputWrapper>
            </StyledSpan>
          )}
          <StyledSpan>
            Corretor: <StyledBrokerName>{record.brokerName}</StyledBrokerName>
          </StyledSpan>
        </Footer>
      </Sidebar>
      <Modal
        width={700}
        isOpen={isModalOpen}
        setIsOpen={() => setIsModalOpen(false)}
        footer={false}
      >
        {desiredOrderStatus === 'BILL_PAYMENT_PENDING' && (
          <BillPendingModal
            setIsModalOpen={setIsModalOpen}
            orderId={record.key}
            setShouldReload={setShouldReloadTimelineRequest}
            setOrderStatus={setOrderStatus}
          />
        )}
        {desiredOrderStatus === 'FILLING_NOT_MEET_REQUIREMENTS' && (
          <UpdateStatusDescriptionModal
            orderId={record.key}
            setIsModalOpen={setIsModalOpen}
            setShouldReload={setShouldReloadTimelineRequest}
            setOrderStatus={setOrderStatus}
          />
        )}
        {desiredOrderStatus === 'RISK_ANALYSIS' && (
          <RiskAnalysisModal
            orderId={record.key}
            setIsModalOpen={setIsModalOpen}
            setOrderStatus={setOrderStatus}
          />
        )}
        {desiredOrderStatus === 'CANCELED' && (
          <CancelModal
            orderId={record.key}
            setIsModalOpen={setIsModalOpen}
            setShouldReload={setShouldReloadTimelineRequest}
            setOrderStatus={setOrderStatus}
          />
        )}
        {desiredOrderStatus === 'ANALYSIS' && (
          <AnalysisModal
            orderId={record.key}
            insurerId={record.insurerId}
            setIsModalOpen={setIsModalOpen}
            setShouldReload={setShouldReloadTimelineRequest}
            setOrders={setOrders}
            setOrderStatus={setOrderStatus}
          />
        )}

        {desiredOrderStatus === 'QUOTATION_INSURER' && (
          <QuotationInsurerModal
            insurer={record?.quotation?.insurer}
            orderId={record.key}
            setIsModalOpen={setIsModalOpen}
            setOrderStatus={setOrderStatus}
          />
        )}
      </Modal>
      {desiredOrderStatus === 'WAITING_SIGNATURE' && (
        <Modal
          width={700}
          isOpen={isModalOpen}
          setIsOpen={() => setIsModalOpen(false)}
          footer={false}
        >
          <WaitingSignatureModal
            orderId={record.key}
            setIsModalOpen={setIsModalOpen}
            setShouldReload={setShouldReloadTimelineRequest}
            setShouldFillResponsibleAdminsIds={setShouldFillResponsibleAdminsIds}
            setOrderStatus={setOrderStatus}
            shouldFillResponsibleAdminsIds={shouldFillResponsibleAdminsIds}
          />
        </Modal>
      )}
      <Toast position="bottom-center" />
    </ExpandableRowContainer>
  )
}
