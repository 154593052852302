import { useMutation } from '@tanstack/react-query'

import { api } from '../../http'

type UploadOrderDocumentRequest = {
  data: FormData
  orderId?: string | null
}

async function uploadOrderDocument({ data, orderId }: UploadOrderDocumentRequest) {
  const response = await api.put<{ documentId: string; uploadURL: string }>(
    `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}/documents`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    },
  )
  return response.data
}

export const useUploadOrderDocument = () => {
  return useMutation({
    mutationKey: ['uploadOrderEntityDocument'],
    mutationFn: uploadOrderDocument,
  })
}
