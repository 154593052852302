import dayjs from 'dayjs'

import {
  type GetAdminsByIdDto,
  type GetManagersByFiltersDto,
  GetOrdersByFiltersDto,
  GetOrdersByFiltersOrders,
} from '@/App/clients/healthPlansOrder/dto'

export const assembleOrders = (data?: GetOrdersByFiltersDto | null) => {
  if (!data) return []

  return data.orders.map((order: GetOrdersByFiltersOrders) => {
    return {
      key: order.id,
      isDispatchedToInternalSystem: order?.isDispatchedToInternalSystem,
      effectiveDate: dayjs(order.effectiveDate).format('DD/MM/YYYY') ?? ' - ',
      leadName: order.lead?.name ?? order.quotation?.lead.name,
      leadId: order.lead?.id ?? order.quotation?.lead.id,
      insurer: order.quotation?.insurer ?? order?.insurer ?? ' - ',
      brokerName: order.broker.name,
      type: order.type,
      subtype: order.subtype,
      totalAmount: order.totalAmount,
      managerName: order.broker.manager.name,
      score: order.score,
      responsavel_implantacao: order.broker.manager.admin.name,
      sisweb: order.isRegisteredInInternalSystem ? 'Sim' : 'Não',
      status: order.status,
      brokerId: order.broker.id,
      lifesQuantity: order?.lifesQuantity ?? order.quotation?.lifesQuantity,
      lead: order?.lead ?? order.quotation?.lead,
      isRegisteredInInternalSystem: order.isRegisteredInInternalSystem,
      insurerId: order.insurerId ?? '-',
      quotation: order?.quotation,
      originId: order?.originId,
      orderBroker: order?.orderBroker,
    }
  })
}

export const assembleManagers = (data?: GetManagersByFiltersDto) => {
  return data?.managers.map((manager) => {
    return {
      value: manager.id,
      label: manager.name,
    }
  })
}

export const assembleAdmins = (data?: GetAdminsByIdDto) => {
  return data?.admins.map((admin) => {
    return {
      value: admin.id,
      label: admin.name,
    }
  })
}
