import { holderAliceHealthSchema } from './alice'
import { holderAmilHealthSchema } from './amil'
import { holderBradescoHealthSchema } from './bradesco'
import { holderDefaultHealthSchema } from './default'
import { holderGndiHealthSchema } from './gndi'
import { holderPortoSeguroHealthSchema } from './portoSeguro'
import { holderSulamericaHealthSchema } from './sulamerica'

export const holderHealthSchemas = {
  amil: holderAmilHealthSchema,
  alice: holderAliceHealthSchema,
  gndi: holderGndiHealthSchema,
  portoSeguro: holderPortoSeguroHealthSchema,
  sulamerica: holderSulamericaHealthSchema,
  bradesco: holderBradescoHealthSchema,
  default: holderDefaultHealthSchema,
}
