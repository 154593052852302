import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'

import { kinships } from '../../../helper/alice/kinships'

export function dependentAliceHealthSchema(): DynamicFormSchemaProps {
  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        name: {
          type: ['string', 'null'],
          title: 'Nome Completo*',
        },
        isPartner: {
          type: 'boolean',
          title: 'Sócio/Cônjuge de sócio da empresa?',
          default: false,
        },
        maritalStatus: {
          type: ['string', 'null'],
          title: 'Estado Civil*',
          oneOf: [
            { title: 'Solteiro', const: 'SINGLE' },
            { title: 'Casado', const: 'MARRIED' },
            { title: 'Divorciado', const: 'DIVORCED' },
            { title: 'Viúvo', const: 'WIDOWED' },
            { title: 'Separado', const: 'SEPARATED' },
            { title: 'Outro', const: 'OTHER' },
          ],
        },
        holderKinship: {
          type: ['string', 'null'],
          title: 'Grau de Parentesco*',
          oneOf: kinships.map((kinship) => ({
            title: kinship.label,
            const: kinship.value,
          })),
        },
        phone: {
          type: ['string', 'null'],
          title: 'Telefone*',
        },
        gender: {
          type: ['string', 'null'],
          title: 'Gênero*',
          oneOf: [
            { title: 'Feminino', const: 'FEMALE' },
            { title: 'Masculino', const: 'MALE' },
          ],
        },
        cpf: {
          type: ['string', 'null'],
          title: 'CPF*',
        },
        birthDate: {
          type: ['string', 'null'],
          title: 'Data de Nascimento*',
          format: 'date',
        },
        motherName: {
          type: ['string', 'null'],
          title: 'Nome da Mãe*',
        },
        isAdministrativeLife: {
          type: ['boolean', 'null'],
          title: 'É uma vida administrativa?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: null,
        },
        gracePeriodReduction: {
          type: ['string', 'null'],
          title: 'Redução de Carência*',
          oneOf: [
            {
              title: 'Possui',
              const: 'Possui',
            },
            {
              title: 'Não possui',
              const: 'Não possui',
            },
          ],
        },
        healthPlan: {
          type: 'object',
          properties: {
            planName: {
              type: ['string', 'null'],
              title: 'Plano Médico*',
              oneOf: [{ title: '', const: '' }],
            },
            coparticipation: {
              type: ['string', 'null'],
              title: 'Coparticipação*',
              oneOf: [
                { title: 'Sem coparticipação', const: 'Sem coparticipação' },
                { title: 'Com coparticipação', const: 'Com coparticipação' },
              ],
            },
            lifeAmount: {
              type: ['string', 'null'],
              title: 'Valor Vida Saúde*',
            },
          },
        },
      },
    },
    uiSchema: {
      name: {
        'ui:placeholder': 'Ex.: Maria da Silva',
      },
      maritalStatus: {
        'ui:placeholder': 'Selecione o estado civil',
      },
      holderKinship: {
        'ui:placeholder': 'Selecione o grau de parentesco',
      },
      phone: {
        'ui:placeholder': '(00) 00000 0000',
        'ui:options': {
          mask: 'phone',
        },
      },
      gender: {
        'ui:placeholder': 'Selecione o gênero',
      },
      cpf: {
        'ui:placeholder': '000.000.000-00',
        'ui:options': {
          mask: 'cpf',
        },
      },
      birthDate: {
        'ui:placeholder': '00/00/0000',
      },
      motherName: {
        'ui:placeholder': 'Ex.: Ana da Silva',
      },
      isAdministrativeLife: {
        'ui:widget': 'radio',
      },
      gracePeriodReduction: {
        'ui:placeholder': 'Selecione a carência',
      },
      healthPlan: {
        'ui:options': {
          colSpan: 2,
        },
        planName: {
          'ui:disabled': true,
          'ui:placeholder': 'Selecione o plano',
        },
        coparticipation: {
          'ui:placeholder': 'Selecione a coparticipação',
        },
        lifeAmount: {
          'ui:placeholder': 'R$ 00,00',
          'ui:options': {
            mask: 'money',
          },
        },
      },
    },
  }
}
