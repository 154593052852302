import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { GetBrokersByFiltersDto } from '../dtos'

type GetBrokerBaseRequest = {
  managerId?: string | null
}
type GetBrokerRequest = QueryFunctionContext & GetBrokerBaseRequest

async function getBrokers({ managerId, signal }: GetBrokerRequest) {
  const response = await api.get<GetBrokersByFiltersDto>(
    `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/brokers`,
    {
      signal,
      headers: { Authorization: localStorage.getItem('authToken') },
      params: {
        managerId,
      },
    },
  )
  return response.data
}

export function getBrokersOptions({ managerId }: { managerId: string | undefined }) {
  return queryOptions({
    queryKey: ['brokers', managerId] as const,
    queryFn: async (params) => getBrokers({ managerId, ...params }),
  })
}
