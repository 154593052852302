import { useMutation, useQueryClient } from '@tanstack/react-query'

import { api } from '@/App/clients/http'
import { EntityType } from '@/App/pages/orderDetails/entities/entityAccordion'

import { FillOrderInformationBody, type GetOrderInformationBody } from '../dtos'

export type FillOrderInformationData = FillOrderInformationBody & {
  orderId?: string | null
  entityType?: EntityType | null
}

const fillOrderInformation = async ({
  entities,
  company,
  customerInstructions,
  status,
  effectiveDate,
  totalAmount,
  isRegisteredInInternalSystem,
  insurer,
  insurerId,
  responsibleAdminsIds,
  holderKinship,
  orderId,
  externalBrokerDocument,
  dispatchOrganization,
  dispatchedAt,
  statusUpdateMetadata,
  isRiskDetected,
  originId,
  orderBroker,
  type,
}: FillOrderInformationData) => {
  const response = await api.patch(
    `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}`,
    {
      entities,
      company,
      customerInstructions,
      status,
      effectiveDate,
      totalAmount,
      isRegisteredInInternalSystem,
      insurer,
      insurerId,
      responsibleAdminsIds,
      holderKinship,
      externalBrokerDocument,
      dispatchOrganization,
      dispatchedAt,
      statusUpdateMetadata,
      isRiskDetected,
      originId,
      orderBroker,
      type,
    },
    {
      headers: { Authorization: localStorage.getItem('authToken') },
    },
  )
  return response.data
}

export const useFillOrderInformation = (fillOrderInformationData?: FillOrderInformationData) => {
  const orderId = fillOrderInformationData?.orderId
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['orders', orderId, { entityType: fillOrderInformationData?.entityType }],
    mutationFn: fillOrderInformation,
    async onMutate({ status, isRiskDetected }) {
      await queryClient.cancelQueries({ queryKey: ['order-state'] })
      await queryClient.cancelQueries({ queryKey: ['order', orderId], exact: true })

      const previousOrder = queryClient.getQueryData(['order', orderId])

      if (status) {
        queryClient.setQueryData(['order', orderId], (cachedOrder: GetOrderInformationBody) => {
          return {
            ...cachedOrder,
            status,
            isRiskDetected,
          }
        })
      }

      return {
        previousOrder,
      }
    },
    onError(_err, _newOrder, context) {
      queryClient.setQueryData(['order', orderId], context?.previousOrder)
    },
    onSettled(_newOrder, _error, { orderId }, _context) {
      queryClient.invalidateQueries({ queryKey: ['order', orderId], exact: true })
      queryClient.invalidateQueries({ queryKey: ['order-state'] })
    },
  })
}
