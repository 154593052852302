import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { GetOrderInformationBody } from '../../healthPlansOrders/dtos'
import { api } from '../../http'

type UpdateStatusBaseRequest = {
  nextStatus?: string | null
  statusUpdateMetadata?: Record<string, unknown> | null
  insurerId?: string | null
  responsibleAdminsIds?: Array<string> | null
  order?: GetOrderInformationBody | null
}

async function updateStatus({
  nextStatus,
  insurerId,
  responsibleAdminsIds,
  statusUpdateMetadata,
  order,
}: UpdateStatusBaseRequest) {
  await api.patch(
    `${import.meta.env.VITE_ORDER_STATE_URL}/order/${order?.id}`,
    {
      nextStatus,
      insurerId,
      responsibleAdminsIds,
      statusUpdateMetadata,
      order: {
        originId: order?.originId,
        orderBroker: order?.orderBroker,
        status: order?.status,
        isRegisteredInInsurer: order?.isRegisteredInInsurer ?? false,
        quotation: {
          insurer: order?.quotation?.insurer,
        },
        broker: {
          name: order?.broker?.name,
          mail: order?.broker?.mail,
        },
        company: {
          name: order?.company?.name,
          tradingName: order?.company?.tradingName,
          registrationStatus: order?.company?.registrationStatus,
          openingDate: order?.company?.openingDate,
          document: order?.company?.document,
          phone: order?.company?.phone,
          mail: order?.company?.mail,
          type: order?.company?.type,
          address: order?.company?.address
            ? {
                street: order?.company?.address?.street,
                number: order?.company?.address?.number,
                complement: order?.company?.address?.complement,
                neighborhood: order?.company?.address?.neighborhood,
                city: order?.company?.address?.city,
                state: order?.company?.address?.state,
                zipCode: order?.company?.address?.zipCode,
              }
            : null,
        },
        holders: order?.holders?.map((holder) => ({
          name: holder.name,
          phone: holder.phone,
          mail: holder.mail,
          cpf: holder.cpf,
          address: holder.address
            ? {
                street: holder.address?.street,
                number: holder.address?.number,
                complement: holder.address?.complement,
                neighborhood: holder.address?.neighborhood,
                city: holder.address?.city,
                state: holder.address?.state,
                zipCode: holder.address?.zipCode,
              }
            : null,
          dependents: holder.dependents?.map((dependent) => ({
            name: dependent.name,
            phone: dependent.phone,
            mail: dependent.mail,
            cpf: dependent.cpf,
            address: dependent.address
              ? {
                  street: dependent.address?.street,
                  number: dependent.address?.number,
                  complement: dependent.address?.complement,
                  neighborhood: dependent.address?.neighborhood,
                  city: dependent.address?.city,
                  state: dependent.address?.state,
                  zipCode: dependent.address?.zipCode,
                }
              : null,
            documents: dependent.documents
              ?.filter((document) => document.status === 'UPLOADED')
              .map((document) => ({
                type: document.type,
                fileName: document.fileName,
                status: document.status,
                previewUrl: document.previewURL,
              })),
          })),
        })),
      },
    },
    {
      headers: { Authorization: localStorage.getItem('authToken') },
    },
  )
}

export const useUpdateStatus = (orderId?: string | null) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['order-state', orderId] as const,
    mutationFn: updateStatus,
    async onMutate({ nextStatus }) {
      await queryClient.cancelQueries({ queryKey: ['order-state'] })
      await queryClient.cancelQueries({ queryKey: ['order', orderId], exact: true })

      const previousOrder = queryClient.getQueryData(['order', orderId])

      if (nextStatus) {
        queryClient.setQueryData(['order', orderId], (cachedOrder: GetOrderInformationBody) => {
          return {
            ...cachedOrder,
            status: nextStatus,
          }
        })
      }

      return {
        previousOrder,
      }
    },
    onError(_err, _newOrder, context) {
      queryClient.setQueryData(['order', orderId], context?.previousOrder)
    },
    onSettled() {
      queryClient.invalidateQueries({ queryKey: ['order', orderId], exact: true })
      queryClient.invalidateQueries({ queryKey: ['order-state'] })
    },
  })
}
