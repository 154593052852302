/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

import { useVariant } from '@unleash/proxy-client-react'
import { TabsProps } from 'antd'
import { Tabs } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { healthPlansOrderRequest } from '@/App/clients/healthPlansOrder'
import {
  GetAdminsByIdDto,
  GetManagersByFiltersDto,
  OrdersData,
} from '@/App/clients/healthPlansOrder/dto'
import { useFiltersBrokersQuery } from '@/App/clients/healthPlansOrders/queries/filtersBrokers'
import { useOrdersByFilters } from '@/App/clients/healthPlansOrders/queries/getOrdersByFilters'
import useFetch from '@/App/clients/http'
import { OrderScoreTag } from '@/App/components/OrderScoreTag'
import { SiswebFilter } from '@/App/components/SiswebCode/SiswebFilter'

import { BrokerFilter } from './Filters/BrokerFilter/BrokerFilter'
import { EffectiveDateFilter } from './Filters/DateFilter/EffectiveDateFilter'
import { Filters } from './Filters/Filters'
import { InsurerFilter } from './Filters/InsurerFilter/InsurerFilter'
import { InternalSystemFilter } from './Filters/InternalSystemFilter/InternalSystemFilter'
import { ManagerFilter } from './Filters/ManagerFilter/ManagerFilter'
import { MovideskFilter } from './Filters/MovideskFilter/MovideskFilter'
import { ResponsibleAdminFilter } from './Filters/ResponsibleAdminFilter/ResponsibleAdminFilter'
import { StatusFilter } from './Filters/StatusFilter/StatusFilter'
import { OrderProcessingStatus } from './OrderProcessingStatus'
import { OrderSisweb } from './OrderSiswebStatus'
import { Table } from './Table'
import { assembleAdmins, assembleManagers, assembleOrders } from './assembler'
import { Container } from './style'

export enum Action {
  AddPage = 'ADD_PAGE',
  AddFilter = 'ADD_FILTER',
  RemoveFilter = 'REMOVE_FILTER',
  ReplaceFilter = 'REPLACE_FILTER',
}

export interface IFilters {
  baseStatus?: string[]
  status?: string[]
  effectiveDatePeriod?: string
  initialEffectiveDate?: string
  endEffectiveDate?: string
  brokerId?: string[]
  managerId?: string[]
  responsibleAdminId?: string[]
  isRegisteredInInternalSystem?: string[]
  leadNameOrInsurerId?: string
  insurer?: string[]
  internalSystemId?: string[]
  originId?: string
}

const ProposalsList = () => {
  const [shouldTriggerRequestByPagination, setShouldTriggerRequestByPagination] = useState(false)
  const [page, setPage] = useState(0)
  const [hasNext, setHasNext] = useState(false)

  const [baseStatus, setBaseStatus] = useState<string[]>([])
  const [orders, setOrders] = useState<OrdersData[]>([])
  const [managers, setManagers] = useState<ReturnType<typeof assembleManagers>>([])
  const [selectedTab, setSelectedTab] = useState('1')
  const [admins, setAdmins] = useState<ReturnType<typeof assembleAdmins>>([])
  const [initialTabClick, setInitialTabClick] = useState(false)

  const userMail = localStorage.getItem('userMail')
  const allowedMailsToViewScore = useVariant('bliss-intranet-allowed-users-score')?.payload?.value
  const isUserAllowedToViewScore = userMail && allowedMailsToViewScore?.includes(userMail)

  const filtersBrokersQuery = useFiltersBrokersQuery()

  const [filters, setFilters] = useState<IFilters>({
    status: [],
    effectiveDatePeriod: '',
    initialEffectiveDate: '',
    endEffectiveDate: '',
    brokerId: [],
    managerId: [],
    responsibleAdminId: [],
    isRegisteredInInternalSystem: [],
    leadNameOrInsurerId: '',
    insurer: [],
    internalSystemId: [],
    originId: '',
  })
  const ordersByFiltersQuery = useOrdersByFilters(
    {
      ...filters,
      status: baseStatus.concat(filters.status ?? []),
    },
    page,
  )

  const { setRequestConfig: setManagersRequest, data: managersData } =
    useFetch<GetManagersByFiltersDto>()

  const { setRequestConfig: setAdminsRequest, data: adminsData } = useFetch<GetAdminsByIdDto>()

  useEffect(() => {
    if (filtersBrokersQuery.isError) {
      triggerToast(false, 'Ops, ocorreu um erro ao carregar os corretores')
    }
  }, [filtersBrokersQuery.isError])

  useEffect(() => {
    const getManagersRequestConfig = healthPlansOrderRequest.getManagers()
    const getAdminsRequestConfig = healthPlansOrderRequest.getAdminsByFilters()

    setManagersRequest(getManagersRequestConfig)
    setAdminsRequest(getAdminsRequestConfig)
  }, [])

  useEffect(() => {
    const emptyFilters = {
      status: [],
      effectiveDatePeriod: '',
      initialEffectiveDate: '',
      endEffectiveDate: '',
      brokerId: [],
      managerId: [],
      responsibleAdminId: [],
      isRegisteredInInternalSystem: [],
      leadNameOrInsurerId: '',
      insurer: [],
      internalSystemId: [],
    }

    if (JSON.stringify(filters) === JSON.stringify(emptyFilters) && initialTabClick) {
      setInitialTabClick(false)
      return
    }
  }, [filters])

  useEffect(() => {
    if (shouldTriggerRequestByPagination) {
      setPage(page + 1)
    }
  }, [shouldTriggerRequestByPagination])

  useEffect(() => {
    const orders = assembleOrders(ordersByFiltersQuery.data)
    setOrders((prevData) => (page === 0 ? orders : [...prevData, ...orders]))

    setHasNext(ordersByFiltersQuery.data?.hasNext ?? false)
    setShouldTriggerRequestByPagination(false)
  }, [ordersByFiltersQuery.data])

  useEffect(() => {
    const managers = assembleManagers(managersData)
    setManagers(managers)
  }, [managersData])

  useEffect(() => {
    const admins = assembleAdmins(adminsData)
    setAdmins(admins)
  }, [adminsData])

  useEffect(() => {
    if (initialTabClick) {
      setFilters({
        status: [],
        effectiveDatePeriod: '',
        initialEffectiveDate: '',
        endEffectiveDate: '',
        brokerId: [],
        managerId: [],
        responsibleAdminId: [],
        isRegisteredInInternalSystem: [],
        leadNameOrInsurerId: '',
        insurer: [],
        internalSystemId: [],
      })
    }
  }, [initialTabClick])

  const handleRequest = (
    action: Action,
    filtersValues: { key: string; value: string | string[] }[],
  ) => {
    let status: Array<string> = []

    switch (selectedTab) {
      case '1': {
        status = []
        break
      }
      case '2': {
        status = [
          'FILLING_IN_PROGRESS',
          'OPPORTUNITY_FILLED',
          'FILLED',
          'INSURER_PROCESSING',
          'CUSTOMER_ACCEPTANCE',
          'READY_TO_DISPATCH',
          'ANALYSIS',
          'FILLING_NOT_MEET_REQUIREMENTS',
          'EFFECTIVE_DATE_PENDING',
          'WAITING_SIGNATURE',
          'BILL_PAYMENT_PENDING',
        ]
        break
      }
      case '3': {
        status = ['HIRED']
        break
      }
      case '4': {
        status = ['CANCELED']
        break
      }
      case '5': {
        status = ['RISK_ANALYSIS']
        break
      }
    }

    switch (action) {
      case Action.AddFilter: {
        const keys = filtersValues.map((filter) => filter.key)
        const targetFilters = { ...filters }

        targetFilters.status = [...status, ...(filters.status ?? [])]

        keys.forEach((key) => {
          const targetValue = filtersValues.find((filter) => filter.key === key)?.value

          if (targetValue) {
            if (targetFilters[key]) {
              targetFilters[key] = [
                ...targetFilters[key],
                ...(Array.isArray(targetValue) ? targetValue : [targetValue]),
              ]
            } else {
              targetFilters[key] = Array.isArray(targetValue) ? targetValue : [targetValue]
            }
          }
        })

        setFilters(targetFilters)
        setPage(0)

        break
      }

      case Action.RemoveFilter: {
        const keys = filtersValues.map((filter) => filter.key)
        const targetFilters = { ...filters }

        keys.forEach((key) => {
          if (Array.isArray(targetFilters[key])) {
            targetFilters[key] = targetFilters[key]?.filter(
              (filterValue) =>
                filterValue !== filtersValues.find((filter) => filter.key === key)?.value,
            )
          }
        })

        targetFilters.status = status

        setFilters(targetFilters)
        setPage(0)

        break
      }

      case Action.ReplaceFilter: {
        const keys = filtersValues.map((filter) => filter.key)
        const targetFilters = { ...filters }

        keys.forEach((key) => {
          const value = filtersValues.find((filter) => filter.key === key)?.value

          if (value) {
            targetFilters[key] = value
          }
        })

        keys.map((key) => {
          if (key == 'initialEffectiveDate' && targetFilters['effectiveDatePeriod'] != '') {
            targetFilters['effectiveDatePeriod'] = ''
          }
          if (key == 'effectiveDatePeriod' && targetFilters['initialEffectiveDate'] != '') {
            targetFilters['initialEffectiveDate'] = ''
            targetFilters['endEffectiveDate'] = ''
          }
        })

        setFilters(targetFilters)
        setPage(0)

        break
      }
    }
  }

  const allStatusFilters = [
    {
      key: '1',
      label: 'Status',
      children: (
        <StatusFilter handleRequest={handleRequest} selectedStatuses={filters.status ?? []} />
      ),
    },
    {
      key: '2',
      label: 'Data de vigência',
      children: <EffectiveDateFilter handleRequest={handleRequest} />,
    },
    {
      key: '3',
      label: 'Supervisor',
      children: (
        <ManagerFilter
          data={managers}
          handleRequest={handleRequest}
          selectedManagers={filters.managerId}
        />
      ),
    },
    {
      key: '4',
      label: 'Corretor',
      children: (
        <BrokerFilter handleRequest={handleRequest} selectedBrokers={filters.brokerId ?? []} />
      ),
    },
    {
      key: '8',
      label: 'Código do Sisweb',
      children: (
        <SiswebFilter
          placeHolder="Busque proposta pelo código do corretor"
          handleRequest={handleRequest}
          selectedCodes={filters.internalSystemId ?? []}
        />
      ),
    },
    {
      key: '7',
      label: 'Operadora',
      children: (
        <InsurerFilter handleRequest={handleRequest} selectedInsurers={filters.insurer ?? []} />
      ),
    },
    {
      key: '5',
      label: 'Responsável pela proposta',
      children: (
        <ResponsibleAdminFilter
          data={admins ?? []}
          handleRequest={handleRequest}
          selectedResponsibleAdmins={filters.responsibleAdminId}
        />
      ),
    },
    {
      key: '6',
      label: 'Cadastrado no sisweb',
      children: (
        <InternalSystemFilter
          handleRequest={handleRequest}
          selectedInternalSystem={filters.isRegisteredInInternalSystem ?? []}
        />
      ),
    },
    {
      key: '9',
      label: 'Movidesk ID',
      children: <MovideskFilter handleRequest={handleRequest} placeHolder="Buscar movidesk Id" />,
    },
  ]

  const inProgressFilters = [
    {
      key: '1',
      label: 'Data de vigência',
      children: <EffectiveDateFilter handleRequest={handleRequest} />,
    },
    {
      key: '2',
      label: 'Supervisor',
      children: (
        <ManagerFilter
          data={managers}
          handleRequest={handleRequest}
          selectedManagers={filters.managerId}
        />
      ),
    },
    {
      key: '3',
      label: 'Corretor',
      children: (
        <BrokerFilter handleRequest={handleRequest} selectedBrokers={filters.brokerId ?? []} />
      ),
    },
    {
      key: '8',
      label: 'Código do Sisweb',
      children: (
        <SiswebFilter
          placeHolder="Busque proposta pelo código do corretor"
          handleRequest={handleRequest}
          selectedCodes={filters.internalSystemId ?? []}
        />
      ),
    },
    {
      key: '6',
      label: 'Operadora',
      children: (
        <InsurerFilter handleRequest={handleRequest} selectedInsurers={filters.insurer ?? []} />
      ),
    },
    {
      key: '4',
      label: 'Responsável pela proposta',
      children: (
        <ResponsibleAdminFilter
          data={admins ?? []}
          handleRequest={handleRequest}
          selectedResponsibleAdmins={filters.responsibleAdminId}
        />
      ),
    },
    {
      key: '5',
      label: 'Cadastrado no sisweb',
      children: (
        <InternalSystemFilter
          handleRequest={handleRequest}
          selectedInternalSystem={filters.isRegisteredInInternalSystem ?? []}
        />
      ),
    },
  ]

  const canceledFilters = [
    {
      key: '2',
      label: 'Data de vigência',
      children: <EffectiveDateFilter handleRequest={handleRequest} />,
    },
    {
      key: '3',
      label: 'Supervisor',
      children: (
        <ManagerFilter
          data={managers}
          handleRequest={handleRequest}
          selectedManagers={filters.managerId}
        />
      ),
    },
    {
      key: '4',
      label: 'Corretor',
      children: (
        <BrokerFilter handleRequest={handleRequest} selectedBrokers={filters.brokerId ?? []} />
      ),
    },
    {
      key: '8',
      label: 'Código do Sisweb',
      children: (
        <SiswebFilter
          placeHolder="Busque proposta pelo código do corretor"
          handleRequest={handleRequest}
          selectedCodes={filters.internalSystemId ?? []}
        />
      ),
    },
    {
      key: '5',
      label: 'Operadora',
      children: (
        <InsurerFilter handleRequest={handleRequest} selectedInsurers={filters.insurer ?? []} />
      ),
    },
    {
      key: '6',
      label: 'Cadastrado no sisweb',
      children: (
        <InternalSystemFilter
          handleRequest={handleRequest}
          selectedInternalSystem={filters.isRegisteredInInternalSystem ?? []}
        />
      ),
    },
  ]

  const hiredFilters = [
    {
      key: '2',
      label: 'Data de vigência',
      children: <EffectiveDateFilter handleRequest={handleRequest} />,
    },
    {
      key: '3',
      label: 'Supervisor',
      children: (
        <ManagerFilter
          data={managers}
          handleRequest={handleRequest}
          selectedManagers={filters.managerId}
        />
      ),
    },
    {
      key: '4',
      label: 'Corretor',
      children: (
        <BrokerFilter handleRequest={handleRequest} selectedBrokers={filters.brokerId ?? []} />
      ),
    },
    {
      key: '8',
      label: 'Código do Sisweb',
      children: (
        <SiswebFilter
          placeHolder="Busque proposta pelo código do corretor"
          handleRequest={handleRequest}
          selectedCodes={filters.internalSystemId ?? []}
        />
      ),
    },
    {
      key: '5',
      label: 'Operadora',
      children: (
        <InsurerFilter handleRequest={handleRequest} selectedInsurers={filters.insurer ?? []} />
      ),
    },
    {
      key: '6',
      label: 'Cadastrado no sisweb',
      children: (
        <InternalSystemFilter
          handleRequest={handleRequest}
          selectedInternalSystem={filters.isRegisteredInInternalSystem ?? []}
        />
      ),
    },
  ]

  const tableColumns = [
    {
      title: 'Nº da proposta na operadora',
      dataIndex: 'insurerId',
      key: 'insurerId',
    },
    { title: 'Operadora', dataIndex: 'insurer', key: 'insurer' },
    { title: 'Movidesk ID', dataIndex: 'originId', key: 'originId' },
    { title: 'Lead', dataIndex: 'leadName', key: 'leadName' },

    { title: 'Supervisor', dataIndex: 'managerName', key: 'managerName' },
    {
      title: 'Corretor/a',
      dataIndex: 'brokerName',
      key: 'brokerName',
    },
    {
      title: 'Cadastrado no sisweb',
      dataIndex: 'sisweb',
      key: 'sisweb',
      render: (sisweb: string, record: OrdersData) => (
        <OrderSisweb
          status={record.status}
          sisweb={sisweb}
          orderId={record.key}
          isDispatchedToInternalSystem={record?.isDispatchedToInternalSystem}
        />
      ),
    },

    {
      title: 'Status',
      key: 'state',
      dataIndex: 'status',
      render: (status: string, record: OrdersData) => (
        <OrderProcessingStatus status={status} orderId={record.key} />
      ),
    },
    ...(isUserAllowedToViewScore
      ? [
          {
            title: 'Score de Risco',
            key: 'score',
            dataIndex: 'score',
            render: (score?: number | null) => {
              return <OrderScoreTag score={score} />
            },
          },
        ]
      : []),
  ]

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: `Todas`,
      children: (
        <>
          <Filters
            baseStatus={baseStatus}
            statusFilters={true}
            items={allStatusFilters}
            selectedFilters={filters}
            setFilters={setFilters}
            handleRequest={handleRequest}
          />
          <Table
            tableColumns={tableColumns}
            tableRows={orders}
            setOrders={setOrders}
            isLoading={ordersByFiltersQuery.isLoading}
            hasNext={hasNext}
            setShouldTriggerRequestByPagination={setShouldTriggerRequestByPagination}
          />
        </>
      ),
    },
    {
      key: '2',
      label: `Em andamento`,
      children: (
        <>
          <Filters
            baseStatus={baseStatus}
            statusFilters={false}
            items={inProgressFilters}
            selectedFilters={filters}
            setFilters={setFilters}
            handleRequest={handleRequest}
          />
          <Table
            tableColumns={tableColumns}
            tableRows={orders}
            setOrders={setOrders}
            isLoading={ordersByFiltersQuery.isLoading}
            hasNext={hasNext}
            setShouldTriggerRequestByPagination={setShouldTriggerRequestByPagination}
          />
        </>
      ),
    },
    {
      key: '3',
      label: `Implantadas`,
      children: (
        <>
          <Filters
            baseStatus={baseStatus}
            statusFilters={false}
            items={canceledFilters}
            selectedFilters={filters}
            setFilters={setFilters}
            handleRequest={handleRequest}
          />
          <Table
            tableColumns={tableColumns}
            tableRows={orders}
            isLoading={ordersByFiltersQuery.isLoading}
            hasNext={hasNext}
            setShouldTriggerRequestByPagination={setShouldTriggerRequestByPagination}
            setOrders={setOrders}
          />
        </>
      ),
    },
    {
      key: '4',
      label: `Perdidas`,
      children: (
        <>
          <Filters
            baseStatus={baseStatus}
            statusFilters={false}
            items={hiredFilters}
            selectedFilters={filters}
            setFilters={setFilters}
            handleRequest={handleRequest}
          />
          <Table
            tableColumns={tableColumns}
            tableRows={orders}
            isLoading={ordersByFiltersQuery.isLoading}
            hasNext={hasNext}
            setShouldTriggerRequestByPagination={setShouldTriggerRequestByPagination}
            setOrders={setOrders}
          />
        </>
      ),
    },
    ...(isUserAllowedToViewScore
      ? [
          {
            key: '5',
            label: `Em análise de risco`,
            children: (
              <>
                <Filters
                  baseStatus={baseStatus}
                  statusFilters={false}
                  items={hiredFilters}
                  selectedFilters={filters}
                  setFilters={setFilters}
                  handleRequest={handleRequest}
                />
                <Table
                  tableColumns={tableColumns}
                  tableRows={orders}
                  isLoading={ordersByFiltersQuery.isLoading}
                  hasNext={hasNext}
                  setShouldTriggerRequestByPagination={setShouldTriggerRequestByPagination}
                  setOrders={setOrders}
                />
              </>
            ),
          },
        ]
      : []),
  ]

  const handleTabClick = (key: string) => {
    switch (key) {
      case '1': {
        setSelectedTab(key)

        setInitialTabClick(true)
        setPage(0)

        setBaseStatus([])
        break
      }
      case '2': {
        setSelectedTab(key)
        setInitialTabClick(true)
        setPage(0)

        const targetStatus = [
          'FILLING_IN_PROGRESS',
          'FILLED',
          'INSURER_PROCESSING',
          'WAITING_SIGNATURE',
          'READY_TO_DISPATCH',
          'ANALYSIS',
          'FILLING_NOT_MEET_REQUIREMENTS',
          'BILL_PAYMENT_PENDING',
        ]
        setBaseStatus(targetStatus)
        break
      }

      case '3': {
        setSelectedTab(key)
        setInitialTabClick(true)
        setPage(0)
        setBaseStatus(['HIRED'])
        break
      }

      case '4': {
        setSelectedTab(key)
        setInitialTabClick(true)
        setPage(0)
        setBaseStatus(['CANCELED'])
        break
      }
      case '5': {
        setSelectedTab(key)
        setInitialTabClick(true)
        setPage(0)
        setBaseStatus(['RISK_ANALYSIS'])
        break
      }
      default:
        break
    }
  }

  return (
    <>
      <Container>
        <Tabs items={tabItems} itemsColor="#514984" onTabClick={handleTabClick} />
      </Container>
    </>
  )
}

export default ProposalsList
