import { Control, UseFormRegister } from 'react-hook-form'

import RadioGroup from 'design-system/Radio/Radio'
import { Select } from 'design-system/components'

import { FillQuotationInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { useGetCitiesQuery } from '@/App/clients/healthPlansOrders/queries/getCities'
import { states } from '@/App/helpers/states'

import { FormItem, FormItemLabel, FormItemRadio } from '../style'

interface GndiFormQuotationProps {
  control: Control<FillQuotationInformationBody>
  register: UseFormRegister<FillQuotationInformationBody>
  formValues: FillQuotationInformationBody
}

export const GndiFormQuotation: React.FC<GndiFormQuotationProps> = ({
  control,
  register,
  formValues,
}) => {
  const getCities = useGetCitiesQuery()

  return (
    <>
      <FormItem>
        <FormItemLabel>Coparticipação*</FormItemLabel>
        <FormItemRadio>
          <RadioGroup
            name="isCoparticipation"
            options={[
              { value: 'true', label: 'Completa' },
              { value: 'false', label: 'Parcial' },
            ]}
            defaultValue={formValues?.isCoparticipation ? 'true' : 'false'}
            register={{
              ...register('isCoparticipation'),
            }}
          />
        </FormItemRadio>
      </FormItem>
      <FormItem>
        <FormItemLabel>Estado Beneficiários (Maioria)*</FormItemLabel>
        <Select
          placeholder="Selecione a opção"
          control={control}
          showSearch={true}
          name="state"
          options={states}
        />
      </FormItem>

      <FormItem>
        <FormItemLabel>Cidade Beneficiários (Maioria)*</FormItemLabel>
        <Select
          placeholder="Selecione a opção"
          control={control}
          showSearch={true}
          name="city"
          options={getCities?.data ?? []}
        />
      </FormItem>
    </>
  )
}
