export const accommodationTypesBradesco = [
  { title: 'Apartamento', const: 'Apartamento' },
  { title: 'Enfermaria', const: 'Enfermaria' },
  { title: 'Odonto', const: 'Odonto' },
  { title: 'Quarto Privativo', const: 'Quarto Privativo' },
  { title: 'Multiplo 2', const: 'Multiplo 2' },
  { title: 'Multiplo 3', const: 'Multiplo 3' },
  { title: 'Multiplo 4', const: 'Multiplo 4' },
  { title: 'Multiplo 6', const: 'Multiplo 6' },
  { title: 'Multiplo 8', const: 'Multiplo 8' },
  { title: 'Multiplo 10', const: 'Multiplo 10' },
]

export const accommodationDentalTypesBradesco = [{ title: 'Não se Aplica', const: 'Não se Aplica' }]
