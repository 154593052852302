export const kinships = [
  { label: 'Filho(a)', value: 'CHILDREN' },
  { label: 'Cônjuge', value: 'SPOUSE' },
  { label: 'Enteado(a)', value: 'STEPCHILDREN' },
  { label: 'Irmão(ã)', value: 'SIBLINGS' },
  { label: 'Sobrinho(a)', value: 'NEPHEWS' },
  { label: 'Neto(a)', value: 'GRANDSON' },
  { label: 'Pai/Mãe', value: 'FATHER' },
  { label: 'Padrasto/Madrasta', value: 'STEPFATHER' },
  { label: 'Genro/Nora', value: 'SON-IN-LAW' },
]
