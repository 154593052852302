import { ReactNode, useEffect, useMemo, useState } from 'react'
import {
  Control,
  FormState,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  useForm,
} from 'react-hook-form'

import {
  DeleteOutlined,
  IdcardOutlined,
  UserOutlined,
  VerticalAlignBottomOutlined,
  WarningFilled,
} from '@ant-design/icons'
import Person from '@design-system/icons/person'
import { colors } from '@design-system/styles/theme'
import type { UseQueryResult } from '@tanstack/react-query'
import { useVariant } from '@unleash/proxy-client-react'
import { OnFileUploadInput } from 'design-system/Upload/Upload'
import CheckCircleIcon from 'design-system/checkCircle'
import {
  Accordion,
  Button,
  Skeleton,
  Spinner,
  TabsButton,
  UploadArea,
} from 'design-system/components'
import FailCircleIcon from 'design-system/failCircle'
import InProgressIcon from 'design-system/inProgressCircle'
import { triggerToast } from 'design-system/triggers'
import { v4 as uuidV4 } from 'uuid'

import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import {
  DocumentDto,
  Entity,
  type GetOrderInformationBody,
} from '@/App/clients/healthPlansOrders/dtos'
import { useFillOrderInformation } from '@/App/clients/healthPlansOrders/mutations/fillOrderInformation'
import useFetch, { useFetchParallel } from '@/App/clients/http'
import type { UseFetchConfig } from '@/App/clients/http/interface'
import { useLatestPersonDispatchEvent } from '@/App/clients/orderGateway/queries/getLatestPersonDispatchEvent'
import { CustomDocumentTable } from '@/App/components/CustomDocumentTable'
import { useDynamicForm } from '@/App/contexts/dynamicForm/DynamicFormProvider'
import { transformAddressToEntityAddress } from '@/App/utils/parseAddress'

import { EntityType } from '..'
import {
  getRequiredCompanyFieldsAlice,
  getRequiredDependentFieldsAlice,
  getRequiredHolderGroupFieldsAlice,
} from '../helper/alice/requiredFields'
import {
  getRequiredCompanyFieldsAmil,
  getRequiredDependentFieldsAmil,
  getRequiredHolderGroupFieldsAmil,
} from '../helper/amil/requiredFields'
import {
  getRequiredCompanyFieldsBradesco,
  getRequiredDependentFieldsBradesco,
  getRequiredHolderGroupFieldsBradesco,
} from '../helper/bradesco/requiredFields'
import {
  getRequiredCompanyFieldsGndi,
  getRequiredDependentFieldsGndi,
  getRequiredHolderGroupFieldsGndi,
} from '../helper/gndi/requiredFields'
import { getIcon, getTitle } from '../helper/helper'
import {
  getRequiredCompanyFieldsPorto,
  getRequiredDependentFieldsPorto,
  getRequiredHolderGroupFieldsPorto,
} from '../helper/porto/requiredFields'
import {
  getRequiredCompanyFieldsSisweb,
  getRequiredDependentsFieldsSisweb,
  getRequiredHolderFieldsSisweb,
  getRequiredHolderGroupFieldsSisweb,
} from '../helper/sisweb/requiredFields'
import {
  getRequiredCompanyFieldsSulamerica,
  getRequiredDependentFieldsSulamerica,
  getRequiredHolderGroupFieldsSulamerica,
} from '../helper/sulamerica/requiredFields'
import { DocumentUploadModal } from './documentUploadModal'
import {
  AccordionChildrenDiv,
  AccordionContent,
  AccordionHeader,
  AccordionHeaderIcon,
  AccordionHeaderInner,
  AccordionHeaderTitleDiv,
  AccordionSubtitle,
  AccordionTitle,
  CreateOrderHolderDependentButton,
  CreateOrderHolderDependentButtonText,
  DocumentActionButtonDiv,
  DownloadDocumentsButtonDiv,
  HolderGroupDiv,
  PersonDispatchEventContainer,
  PersonDispatchEventText,
  TabsLabel,
  UploadFilesLoadingDiv,
  WarningMessage,
} from './style'

export interface AccordionFrameProps {
  entityType: EntityType
  index?: number
  entityData?: Entity | null
  content: (
    register: UseFormRegister<Entity>,
    control: Control<Entity>,
    watch: UseFormWatch<Entity>,
    formState: FormState<Entity>,
    reset: UseFormReset<Entity>,
    setFormValue?: UseFormSetValue<Entity>,
    trigger?: UseFormTrigger<Entity>,
  ) => ReactNode
  backgroundColor?: string | null
  isInner?: boolean
  validateOrderDispatch?: boolean
  validateSiswebDispatch?: boolean
  orderInformationQuery?: UseQueryResult<GetOrderInformationBody, Error>
  isOcrProcessing: boolean
  setIsOcrProcessing: React.Dispatch<React.SetStateAction<boolean>>
}

export const AccordionFrame: React.FC<AccordionFrameProps> = ({
  entityType,
  entityData,
  index = 0,
  content,
  backgroundColor = '#ffffff',
  orderInformationQuery,
  isInner = false,
  validateOrderDispatch,
  validateSiswebDispatch,
  isOcrProcessing,
  setIsOcrProcessing,
}) => {
  const [isDocumentInspectorVisible, setIsDocumentInspectorVisible] = useState(false)
  const [isDocumentUploadModalVisible, setIsDocumentUploadModalVisible] = useState(false)
  const [uploadedDocumentFiles, setUploadedDocumentFiles] = useState<OnFileUploadInput[]>()
  const [downloadedDocumentFileName, setDownloadedDocumentFileName] = useState<string>()

  const [isLoadingUploadFileDiv, setIsLoadingUploadFileDiv] = useState(false)
  const [isLoadingDivCreateOrderHolderDependent, setIsLoadingDivCreateOrderHolderDependent] =
    useState(false)
  const [isLoadingDivDeleteOrderHolder, setIsLoadingDivDeleteOrderHolder] = useState(false)
  const [isLoadingDivDeleteOrderEntity, setIsLoadingDivDeleteOrderEntity] = useState(false)

  const [selectedDocument, setSelectedDocument] = useState('')

  const orderData = orderInformationQuery?.data

  const {
    register,
    control,
    watch,
    formState,
    reset,
    setValue: setFormValue,
    trigger,
  } = useForm<Entity>({
    defaultValues: entityData ?? {},
    mode: 'onBlur',
  })

  const fillOrderInformationMutation = useFillOrderInformation()
  const personDispatchEventsQuery = useLatestPersonDispatchEvent({
    personInternalId: entityData?.id,
    enabled: orderData?.status === 'HIRED' && ['HOLDER', 'DEPENDENT'].includes(entityType),
  })
  const { setEntityExclusionDispatchModal } = useDynamicForm()
  const allowedDispatchPersonInsurers = useVariant('bliss-intranet-dispatch-person-event')

  const {
    setRequestConfig: setUploadFilesRequestConfigs,
    statusCode: uploadFilesStatusCodes,
    data: uploadFilesData,
    isLoading: isLoadingUploadFiles,
  } = useFetchParallel<{ uploadURL?: string }>()

  const {
    setRequestConfig: setUploadFilesByUploadURLRequestConfig,
    statusCode: uploadFilesByUploadURLStatusCodes,
    isLoading: isLoadingUploadFilesByUploadURL,
  } = useFetchParallel()

  const {
    setRequestConfig: setDeleteDocumentRequestConfig,
    statusCode: deleteDocumentStatusCode,
    isLoading: isLoadingDeleteDocument,
  } = useFetch()

  const {
    setRequestConfig: setDownloadEntityDocumentFilesRequestConfig,
    data: downloadEntityDocumentsData,
    fileName: downloadEntityDocumentsFilesFileName,
    isLoading: isLoadingDownloadEntityDocumentFiles,
  } = useFetch<ArrayBuffer>()

  const {
    setRequestConfig: setDownloadDocumentByPreviewURLRequestConfig,
    data: downloadDocumentByPreviewURLData,
    isLoading: isLoadindDownloadDocumentByPreviewURL,
  } = useFetch<ArrayBuffer>()

  const {
    setRequestConfig: setDeleteOrderEntityRequestConfig,
    isLoading: isLoadingDeleteOrderEntity,
    statusCode: deleteOrderEntityStatusCode,
  } = useFetch()

  const {
    setRequestConfig: setCreateOrderHolderDependentRequestConfig,
    isLoading: isLoadingCreateOrderHolderDependent,
    statusCode: createOrderHolderDependentStatusCode,
  } = useFetch()

  const {
    setRequestConfig: setDeleteOrderHolderRequestConfig,
    isLoading: isLoadingDeleteOrderHolder,
    statusCode: deleteOrderHolderStatusCode,
  } = useFetch()

  useEffect(() => {
    if (fillOrderInformationMutation.isSuccess) {
      orderInformationQuery?.refetch()
    }
  }, [fillOrderInformationMutation.isSuccess])

  useEffect(() => {
    if (!orderInformationQuery?.isRefetching) {
      setIsLoadingUploadFileDiv(false)
      setIsLoadingDivDeleteOrderEntity(false)
      setIsLoadingDivCreateOrderHolderDependent(false)
      setIsLoadingDivDeleteOrderHolder(false)
    }
  }, [orderInformationQuery?.isRefetching])

  useEffect(() => {
    if (isLoadingUploadFiles) setIsLoadingUploadFileDiv(true)
  }, [isLoadingUploadFiles])

  useEffect(() => {
    if (isLoadingDeleteOrderEntity) setIsLoadingDivDeleteOrderEntity(true)
  }, [isLoadingDeleteOrderEntity])

  useEffect(() => {
    if (isLoadingCreateOrderHolderDependent) setIsLoadingDivCreateOrderHolderDependent(true)
  }, [isLoadingCreateOrderHolderDependent])

  useEffect(() => {
    if (isLoadingDeleteOrderHolder) setIsLoadingDivDeleteOrderHolder(true)
  }, [isLoadingDeleteOrderHolder])

  useEffect(() => {
    if (createOrderHolderDependentStatusCode === 201) {
      triggerToast(true, 'Dependente adicionado com sucesso ao grupo titular!')
      setIsLoadingDivCreateOrderHolderDependent(false)
      orderInformationQuery?.refetch()
    } else if (createOrderHolderDependentStatusCode && createOrderHolderDependentStatusCode > 400)
      triggerToast(false, 'Ops, ocorreu um erro ao adicionar um dependente ao grupo titular')
  }, [createOrderHolderDependentStatusCode])

  useEffect(() => {
    if (deleteOrderHolderStatusCode === 200) {
      triggerToast(true, 'Grupo titular removido com sucesso!')
      orderInformationQuery?.refetch()
    } else if (deleteOrderHolderStatusCode && deleteOrderHolderStatusCode > 400)
      triggerToast(false, 'Ops, ocorreu um erro ao remover o grupo titular')
  }, [deleteOrderHolderStatusCode])

  useEffect(() => {
    if (deleteOrderEntityStatusCode === 200) {
      triggerToast(true, 'Beneficiário removido com sucesso!')
      orderInformationQuery?.refetch()
    } else if (deleteOrderEntityStatusCode && deleteOrderEntityStatusCode > 400)
      triggerToast(false, 'Ops, ocorreu um erro ao remover o beneficiário')
  }, [deleteOrderEntityStatusCode])

  useEffect(() => {
    if (fillOrderInformationMutation.isSuccess) {
      triggerToast(true, 'Dados atualizados com sucesso!')
    } else if (fillOrderInformationMutation.isError)
      triggerToast(false, 'Ops, ocorreu um erro ao atualizar os dados')
  }, [fillOrderInformationMutation.isSuccess, fillOrderInformationMutation.isError])

  useEffect(() => {
    if (deleteDocumentStatusCode === 200) {
      triggerToast(true, 'Documento apagado com sucesso!')
      orderInformationQuery?.refetch()
    } else if (deleteDocumentStatusCode && deleteDocumentStatusCode > 400)
      triggerToast(false, 'Ops, ocorreu um erro ao apagar o documento')
  }, [deleteDocumentStatusCode])

  useEffect(() => {
    if (!uploadFilesStatusCodes) return
    const [uploadFilesStatusCode] = uploadFilesStatusCodes

    if (uploadFilesStatusCode === 200) {
      const requestConfigs = uploadFilesData
        .map((data, index) => {
          const contentType = uploadedDocumentFiles?.at(index)?.fileContentType
          if (data.uploadURL && uploadedDocumentFiles && contentType) {
            return HealthPlansOrdersRequests.uploadOrderDocumentByUploadURL(
              data.uploadURL,
              uploadedDocumentFiles[index].fileContent,
              contentType,
            )
          }
        })
        .filter((doc) => doc != null) as Array<UseFetchConfig>

      setUploadFilesByUploadURLRequestConfig(requestConfigs)
    }
  }, [uploadFilesStatusCodes])

  useEffect(() => {
    if (!uploadFilesByUploadURLStatusCodes) return
    const [uploadFilesStatusCode] = uploadFilesByUploadURLStatusCodes

    if (uploadFilesStatusCode === 200) {
      triggerToast(true, 'Arquivos enviados  com sucesso!')
      setIsDocumentUploadModalVisible(false)
      orderInformationQuery?.refetch()
    } else if (uploadFilesStatusCode > 400)
      triggerToast(false, 'Ops, ocorreu um erro ao enviar os arquivos')
  }, [uploadFilesByUploadURLStatusCodes])

  useEffect(() => {
    if (downloadEntityDocumentsData && downloadEntityDocumentsFilesFileName) {
      const blob = new Blob([downloadEntityDocumentsData])
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', downloadEntityDocumentsFilesFileName)

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
  }, [downloadEntityDocumentsData])

  useEffect(() => {
    if (downloadDocumentByPreviewURLData && downloadedDocumentFileName) {
      const blobs = new Blob([downloadDocumentByPreviewURLData])
      const url = window.URL.createObjectURL(blobs)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', downloadedDocumentFileName)

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
  }, [downloadDocumentByPreviewURLData])

  useEffect(() => {
    if (entityData?.address) {
      const parsedAddress = transformAddressToEntityAddress(entityData?.address).address
        ?.entityAddress

      setFormValue('entityAddress', parsedAddress == null ? null : parsedAddress)
    }
  }, [entityData])

  const downloadEntityDocumentFiles = () => {
    if (orderData?.id && entityData?.id) {
      const downloadEntityDocumentFilesRequestConfig =
        HealthPlansOrdersRequests.downloadEntityDocumentsFiles(orderData.id, entityData.id)

      setDownloadEntityDocumentFilesRequestConfig(downloadEntityDocumentFilesRequestConfig)
    }
  }

  const deleteDocument = (documentId: string, isLegalRepresentative?: boolean) => {
    if (
      isLegalRepresentative &&
      orderData?.id &&
      orderData?.company?.externalLegalRepresentative?.id
    ) {
      const deleteDocumentRequestConfig = HealthPlansOrdersRequests.deleteDocument(
        orderData.id,
        orderData.company.externalLegalRepresentative.id,
        documentId,
      )
      setDeleteDocumentRequestConfig(deleteDocumentRequestConfig)
    }
    if (!isLegalRepresentative && orderData?.id && entityData?.id) {
      setSelectedDocument(documentId)
      const deleteDocumentRequestConfig = HealthPlansOrdersRequests.deleteDocument(
        orderData.id,
        entityData.id,
        documentId,
      )
      setDeleteDocumentRequestConfig(deleteDocumentRequestConfig)
    }
  }

  const downloadDocument = (document?: DocumentDto) => {
    if (document?.previewURL) {
      setDownloadedDocumentFileName(document.fileName)
      setSelectedDocument(document.id)

      const downloadDocumentRequestConfig =
        HealthPlansOrdersRequests.downloadOrderDocumentByPreviewURL(document.previewURL)

      setDownloadDocumentByPreviewURLRequestConfig(downloadDocumentRequestConfig)
    }
  }

  const deleteEntity = async (type: EntityType, entityId?: string | null) => {
    if (orderData?.id && entityId) {
      if (orderData?.status === 'HIRED') {
        setEntityExclusionDispatchModal({ isOpen: true, entity: entityData, entityType: type })

        return
      }

      if (type === 'DEPENDENT') {
        const deleteEntityRequestConfig = HealthPlansOrdersRequests.deleteOrderEntity(
          orderData.id,
          entityId,
        )
        setDeleteOrderEntityRequestConfig(deleteEntityRequestConfig)
      } else {
        const deleteOrderHolderRequestConfig = HealthPlansOrdersRequests.deleteOrderHolder(
          orderData.id,
          entityId,
        )
        setDeleteOrderHolderRequestConfig(deleteOrderHolderRequestConfig)
      }
    }
  }

  const createOrderHolderDependent = async (entityId?: string | null) => {
    if (orderData?.id && entityId) {
      const createOrderHolderDependentRequestConfig =
        HealthPlansOrdersRequests.createOrderHolderDependent(orderData.id, entityId)
      setCreateOrderHolderDependentRequestConfig(createOrderHolderDependentRequestConfig)
    }
  }

  const onFileUpload = (files: OnFileUploadInput[]) => {
    setUploadedDocumentFiles(files)
    setIsDocumentUploadModalVisible(true)
  }

  const hasEmptyOrNullFields = (fieldList) => {
    return fieldList?.some((field) => field === null || field === '')
  }

  const getAccordionValidation = () => {
    const requiredMessage = (
      <WarningMessage>
        <WarningFilled /> Preencha os dados obrigatórios
      </WarningMessage>
    )
    const insurersCompanyRequiredFields = {
      Amil: getRequiredCompanyFieldsAmil(orderData),
      Alice: getRequiredCompanyFieldsAlice(orderData),
      'Porto Seguro': getRequiredCompanyFieldsPorto(orderData),
      GNDI: getRequiredCompanyFieldsGndi(orderData),
      'Notre Dame Intermédica': getRequiredCompanyFieldsGndi(orderData),
      Sulamérica: getRequiredCompanyFieldsSulamerica(orderData),
      'Bradesco Seguros': getRequiredCompanyFieldsBradesco(orderData),
      default: getRequiredCompanyFieldsSisweb(orderData),
    }
    const insurersHolderGroupRequiredFields = {
      Amil: getRequiredHolderGroupFieldsAmil(orderData),
      Alice: getRequiredHolderGroupFieldsAlice(orderData),
      'Porto Seguro': getRequiredHolderGroupFieldsPorto(orderData),
      GNDI: getRequiredHolderGroupFieldsGndi(orderData),
      'Notre Dame Intermédica': getRequiredHolderGroupFieldsGndi(orderData),
      Sulamérica: getRequiredHolderGroupFieldsSulamerica(orderData),
      'Bradesco Seguros': getRequiredHolderGroupFieldsBradesco(orderData),
      default: getRequiredHolderGroupFieldsSisweb(orderData),
    }
    const insurersDependentGroupRequiredFields = {
      Amil: getRequiredDependentFieldsAmil(orderData),
      Alice: getRequiredDependentFieldsAlice(orderData),
      'Porto Seguro': getRequiredDependentFieldsPorto(orderData),
      GNDI: getRequiredDependentFieldsGndi(orderData),
      'Notre Dame Intermédica': getRequiredDependentFieldsGndi(orderData),
      Sulamérica: getRequiredDependentFieldsSulamerica(orderData),
      'Bradesco Seguros': getRequiredDependentFieldsBradesco(orderData),
      default: getRequiredDependentsFieldsSisweb(orderData),
    }

    const companyRequiredFields =
      insurersCompanyRequiredFields[orderData?.quotation?.insurer ?? ''] ??
      insurersCompanyRequiredFields.default
    const holderGroupRequiredFields =
      insurersHolderGroupRequiredFields[orderData?.quotation?.insurer ?? ''] ??
      insurersHolderGroupRequiredFields.default
    const dependentRequiredFields =
      insurersDependentGroupRequiredFields[orderData?.quotation?.insurer ?? ''] ??
      insurersDependentGroupRequiredFields.default

    switch (entityType) {
      case 'COMPANY':
        return hasEmptyOrNullFields(companyRequiredFields) && requiredMessage
      case 'HOLDER_GROUP':
        return hasEmptyOrNullFields(holderGroupRequiredFields) && requiredMessage
      case 'HOLDER':
        return hasEmptyOrNullFields(holderGroupRequiredFields) && requiredMessage
      case 'DEPENDENT':
        return hasEmptyOrNullFields(dependentRequiredFields) && requiredMessage
      default:
        return null
    }
  }

  const getSiswebAccordionValidation = () => {
    const requiredMessage = (
      <WarningMessage>
        <WarningFilled /> Preencha os dados obrigatórios
      </WarningMessage>
    )

    const companyRequiredFields = getRequiredCompanyFieldsSisweb(orderData)
    const holderGroupRequiredFields = getRequiredHolderGroupFieldsSisweb(orderData)
    const holderRequiredFields = getRequiredHolderFieldsSisweb(orderData)
    const dependentsRequiredFields = getRequiredDependentsFieldsSisweb(orderData)

    switch (entityType) {
      case 'COMPANY':
        return hasEmptyOrNullFields(companyRequiredFields) && requiredMessage
      case 'HOLDER_GROUP':
        return hasEmptyOrNullFields(holderGroupRequiredFields) && requiredMessage
      case 'HOLDER':
        return hasEmptyOrNullFields(holderRequiredFields) && requiredMessage
      case 'DEPENDENT':
        return hasEmptyOrNullFields(dependentsRequiredFields) && requiredMessage
      default:
        return null
    }
  }

  const personEventDispatchedAt = useMemo(() => {
    if (!personDispatchEventsQuery.data) {
      return null
    }

    const processingStatus =
      {
        SUCCESS: {
          icon: <CheckCircleIcon />,
          label:
            personDispatchEventsQuery.data?.type === 'INCLUSION' ? 'Incluído em:' : 'Excluído em:',
        },
        PENDING: {
          icon: <InProgressIcon />,
          label:
            personDispatchEventsQuery.data?.type === 'INCLUSION'
              ? 'Solicitado a inclusão em:'
              : 'Solicitado a exclusão em:',
        },
        FAILURE: {
          icon: <FailCircleIcon />,
          label:
            personDispatchEventsQuery.data?.type === 'INCLUSION'
              ? 'Falha na inclusão em:'
              : 'Falha na exclusão em:',
        },
      }[personDispatchEventsQuery.data.processingStatus ?? ''] ?? null

    if (['HOLDER', 'DEPENDENT'].includes(entityType) && orderData?.status === 'HIRED') {
      return (
        <PersonDispatchEventContainer>
          {processingStatus?.icon}
          <PersonDispatchEventText>
            {`${processingStatus?.label} ${personDispatchEventsQuery.data.formattedCreatedAt}`}
          </PersonDispatchEventText>
        </PersonDispatchEventContainer>
      )
    }

    return null
  }, [orderData?.status, orderData?.hiredAt, entityData?.createdAt, personDispatchEventsQuery.data])

  const canDeleteOrDispatchExclusionEvent = useMemo(() => {
    if (orderInformationQuery?.data?.status !== 'HIRED') {
      return true
    }

    if (!allowedDispatchPersonInsurers?.payload?.value) {
      return false
    }

    const allowedInsurers = JSON.parse(allowedDispatchPersonInsurers.payload.value)['EXCLUSION']

    if (!allowedInsurers) {
      return false
    }

    if (!allowedInsurers.includes(orderInformationQuery.data?.quotation?.insurer ?? '')) {
      return false
    }

    if (!personDispatchEventsQuery.data) {
      return true
    }

    if (personDispatchEventsQuery.data?.type === 'INCLUSION') {
      return true
    }

    return (
      personDispatchEventsQuery.data?.type === 'EXCLUSION' &&
      personDispatchEventsQuery.data?.processingStatus === 'FAILURE'
    )
  }, [
    orderInformationQuery?.data?.status,
    personDispatchEventsQuery.data?.type,
    personDispatchEventsQuery.data?.processingStatus,
  ])

  const hasEntityCreatedBeforeOrderWasHired =
    String(entityData?.createdAt) < String(orderInformationQuery?.data?.hiredAt) ||
    entityType === 'COMPANY'
  const shouldDisableForm =
    (orderInformationQuery?.data?.status === 'HIRED' && hasEntityCreatedBeforeOrderWasHired) ||
    ['SUCCESS', 'PENDING'].includes(personDispatchEventsQuery.data?.processingStatus ?? '')

  return (
    <Accordion
      innerPadding={!isInner}
      backgroundColor={entityType === 'HOLDER_GROUP' || isInner ? backgroundColor : null}
      header={
        isInner ? (
          <AccordionHeaderInner>
            <AccordionHeaderTitleDiv>
              <AccordionHeaderIcon>{getIcon(entityType)}</AccordionHeaderIcon>

              <AccordionTitle>{getTitle(entityType, index)}</AccordionTitle>
              <AccordionSubtitle>{entityData?.name}</AccordionSubtitle>
            </AccordionHeaderTitleDiv>

            {personEventDispatchedAt ? personEventDispatchedAt : null}
          </AccordionHeaderInner>
        ) : (
          <AccordionHeader>
            <AccordionHeaderTitleDiv>
              <AccordionHeaderIcon>{getIcon(entityType)}</AccordionHeaderIcon>

              <AccordionTitle>{getTitle(entityType, index)}</AccordionTitle>
              <AccordionSubtitle>
                {entityType === 'HOLDER_GROUP'
                  ? ''
                  : entityType === 'COMPANY'
                  ? entityData?.tradingName
                  : entityData?.name}
              </AccordionSubtitle>
            </AccordionHeaderTitleDiv>

            {validateOrderDispatch && !validateSiswebDispatch && getAccordionValidation()}
            {validateSiswebDispatch && getSiswebAccordionValidation()}
            {personEventDispatchedAt ? personEventDispatchedAt : null}
          </AccordionHeader>
        )
      }
      footer={
        ['DEPENDENT', 'HOLDER_GROUP'].includes(entityType) && canDeleteOrDispatchExclusionEvent
          ? {
              icon: <DeleteOutlined />,
              onClick: () => deleteEntity(entityType, entityData?.id),
              isLoading:
                isLoadingDivDeleteOrderEntity ||
                isLoadingDivDeleteOrderHolder ||
                isLoadingDeleteDocument,
            }
          : null
      }
      fixedFooterSize={true}
    >
      <AccordionContent backgroundColor={backgroundColor}>
        {entityType === 'HOLDER_GROUP' ? (
          <HolderGroupDiv>
            {content(register, control, watch, formState, reset, setFormValue, trigger)}
            <CreateOrderHolderDependentButton
              disabled={!canDeleteOrDispatchExclusionEvent}
              onClick={() =>
                !isLoadingCreateOrderHolderDependent || orderInformationQuery?.isLoading
                  ? createOrderHolderDependent(entityData?.id)
                  : null
              }
            >
              {isLoadingDivCreateOrderHolderDependent ? (
                <Spinner />
              ) : (
                <>
                  <Person />
                  <CreateOrderHolderDependentButtonText>
                    Adicionar dependentes
                  </CreateOrderHolderDependentButtonText>
                </>
              )}
            </CreateOrderHolderDependentButton>
          </HolderGroupDiv>
        ) : (
          <TabsButton
            colorBackgorund="#514984"
            items={[
              {
                key: '0',
                label: (
                  <TabsLabel>
                    <UserOutlined />{' '}
                    {entityType === 'COMPANY' ? 'Dados da empresa' : 'Dados Pessoais'}
                  </TabsLabel>
                ),
                children: (
                  <AccordionChildrenDiv backgroundColor={backgroundColor}>
                    {content(register, control, watch, formState, reset, setFormValue, trigger)}
                  </AccordionChildrenDiv>
                ),
              },
              {
                key: '1',
                label: (
                  <TabsLabel>
                    <IdcardOutlined /> Documentos
                  </TabsLabel>
                ),
                children: (
                  <AccordionChildrenDiv>
                    {isLoadingUploadFileDiv ? (
                      <UploadFilesLoadingDiv>
                        <Skeleton
                          type="default"
                          shape="square"
                          active={true}
                          loading={true}
                          title={{ width: '400px' }}
                        />
                      </UploadFilesLoadingDiv>
                    ) : (
                      <>
                        <UploadArea
                          onFileUpload={(files) =>
                            onFileUpload(files.map((file) => ({ ...file, uid: uuidV4() })))
                          }
                          disabled={shouldDisableForm}
                        />
                        <DocumentActionButtonDiv>
                          <DownloadDocumentsButtonDiv>
                            <Button
                              onClick={downloadEntityDocumentFiles}
                              backgroundColor={colors.darkPurple}
                              loading={isLoadingDownloadEntityDocumentFiles}
                              height="36px"
                            >
                              <VerticalAlignBottomOutlined style={{ color: 'white' }} />
                              <p>Baixar tudo</p>
                            </Button>
                          </DownloadDocumentsButtonDiv>
                        </DocumentActionButtonDiv>

                        <CustomDocumentTable
                          entityData={entityData}
                          selectedDocument={selectedDocument}
                          isLoadindDownloadDocumentByPreviewURL={
                            isLoadindDownloadDocumentByPreviewURL
                          }
                          isLoadingDeleteDocument={isLoadingDeleteDocument}
                          deleteDocument={deleteDocument}
                          downloadDocument={downloadDocument}
                          isDocumentInspectorVisible={isDocumentInspectorVisible}
                          setIsDocumentInspectorVisible={setIsDocumentInspectorVisible}
                          entityType={entityType}
                          insurer={orderData?.quotation?.insurer}
                          orderId={orderData?.id}
                          reloadOrderData={orderInformationQuery?.refetch}
                          isLoadingReloadOrderData={orderInformationQuery?.isRefetching ?? false}
                          validateOrderDispatch={validateOrderDispatch}
                          isOcrProcessing={isOcrProcessing}
                          setIsOcrProcessing={setIsOcrProcessing}
                          disabled={shouldDisableForm}
                        />
                      </>
                    )}
                  </AccordionChildrenDiv>
                ),
              },
            ]}
          />
        )}
      </AccordionContent>

      {uploadedDocumentFiles && uploadedDocumentFiles?.length > 0 && (
        <DocumentUploadModal
          documents={uploadedDocumentFiles}
          visibility={isDocumentUploadModalVisible}
          setVisibility={setIsDocumentUploadModalVisible}
          setUploadFilesRequestConfigs={setUploadFilesRequestConfigs}
          orderId={orderData?.id}
          entityId={entityData?.id}
          isLoadingUploadFiles={isLoadingUploadFiles || isLoadingUploadFilesByUploadURL}
        />
      )}
    </Accordion>
  )
}
