import styled from 'styled-components'

import { colors } from '../../../../../design-system/src/styles/theme'

export interface FormItemRadioProps {
  isSubtypeVisible?: boolean
}

export interface FormItemSubtypeProps {
  isVisible?: boolean
}

export interface FormItemGroupProps {
  isVisible?: boolean
}

export const QuotationDiv = styled.div`
  border: 1px solid #dcdfeb;
  border-radius: 10px;
  width: 25%;
  font-size: 14px;
  padding: 0 23px 23px 23px;
  margin-right: 15px;
  height: 700px;
  overflow: auto;
`

export const QuotationHeader = styled.div`
  font-size: 17px;
  color: ${colors.primaryText};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-top: 15px;
`

export const QuotationHeaderTitle = styled.p`
  color: #4b4b4b;
  font-weight: 600;
  font-size: 17px;
`

export const QuotationHeaderSubtitle = styled.p`
  color: #878da1;
  flex-direction: column;
  font-size: 13px;
  margin: 0;
`
export const HorizontalLine = styled.hr`
  height: 1px;
  border-width: 0;
  background-color: #dcdfeb;
  width: 100%;
  margin-bottom: 10px;
`

export const EditQuotationButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;

  p {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    color: ${colors.primaryText};
  }

  :hover {
    cursor: pointer;
    background-color: ${colors.mouseHover};
    border-radius: 10px;
  }
`

export const SaveQuotationButtonDiv = styled.div`
  width: 123px;
  font-size: 13px;

  p {
    font-weight: normal;
    margin-left: 5px;
  }
`

export const QuotationInformationDiv = styled.div`
  display: flex;
  flex-direction: column;

  li {
    color: #4b4b4b;

    display: inline;
    margin: 0;
    margin-top: 10px;
    font-weight: 500;
  }

  p {
    color: #878da1;
    margin: 0;
  }
`

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-bottom: 20px;
  gap: 3px;
`

export const FormItemRadio = styled(FormItem)`
  flex-direction: row;
`

export const FormItemGroup = styled.div<FormItemGroupProps>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  width: 95%;
  margin-bottom: 20px;
`

export const FormItemLabel = styled.p`
  font-weight: 500;
  color: ${colors.primaryText} !important;
  text-align: 'start';
`

export const ModalButtonText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;

  p {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    color: ${colors.primaryText};
  }

  :hover {
    cursor: pointer;
    background-color: ${colors.mouseHover};
    border-radius: 10px;
  }
`
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ModalTitle = styled.p`
  font-weight: 600;
  font-size: 17px;
  margin-top: 40px;
`

export const ModalTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-bottom: 30px;

  p {
    text-align: center;
    margin: 0;
    color: #878da1;
  }
`

export const ModalConfirmButtonDiv = styled.div`
  width: 188px;
`
export const AttachmentDocumentDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`
export const ErrorStyled = styled.div`
  align-items: center;
  color: ${colors.red};
  column-gap: 5px;
  display: flex;
  font-family: 'Manrope', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  width: 100%;
`
