import { useEffect, useState } from 'react'

import { CheckOutlined, EditFilled, WarningFilled } from '@ant-design/icons'
import { colors } from '@design-system/styles/theme'
import { Button, SwitchButton } from 'design-system/components'

import { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { useDeleteExternalManager } from '@/App/clients/healthPlansOrders/mutations/deleteExternalManager'
import { useFillExternalManagerInformation } from '@/App/clients/healthPlansOrders/mutations/fillExternalManagerInformation'
import { useFillOrderInformation } from '@/App/clients/healthPlansOrders/mutations/fillOrderInformation'
import { useGetBrokerByIdQuery } from '@/App/clients/healthPlansOrders/queries/brokerById'

import { getRequiredExternalManagerFields } from '../../entities/entityAccordion/helper/helper'
import {
  EditInfoButton,
  ErrorStyled,
  ExternalMain,
  ExternalWrapper,
  FillInsurerIdInput,
  FillInsurerIdInputDiv,
  FillInsurerIdInputLabel,
  SaveInsurerIdButtonDiv,
  WrapperExternalDiv,
} from '../style'

export interface ExternalPersonsProps {
  orderData: GetOrderInformationBody
  reloadOrderData: () => void
  validateOrderDispatch?: boolean
  isLoadingReloadOrderData: boolean
}

export const ManagerPersonType: React.FC<ExternalPersonsProps> = ({
  orderData,
  reloadOrderData,
  validateOrderDispatch,
  isLoadingReloadOrderData,
}) => {
  const [isExternalManager, setIsExternalManager] = useState(orderData?.externalManager !== null)

  const [isEditingMailManager, setIsEditingMailManager] = useState(false)
  const [isEditingPhoneManager, setIsEditingPhoneManager] = useState(false)
  const [isEditingNameManager, setIsEditingNameManager] = useState(false)
  const [isEditingDocumentManager, setIsEditingDocumentManager] = useState(false)

  const [name, setName] = useState<string | null>(null)
  const [document, setDocument] = useState<string | null>(null)
  const [mail, setMail] = useState<string | null>(null)
  const [phone, setPhone] = useState<string | null>(null)

  const fillOrderInformation = useFillOrderInformation()
  const deleteExternalManager = useDeleteExternalManager()
  const fillExternalManagerInformation = useFillExternalManagerInformation()

  const getBrokerinfoQuery = useGetBrokerByIdQuery(orderData?.broker?.id)

  useEffect(() => {
    if (!isExternalManager && orderData?.externalManager !== null) {
      const externalManagerData = {
        orderId: orderData.id,
        externalManagerId: orderData.externalManager?.id,
      }
      deleteExternalManager.mutate(externalManagerData)
    }
  }, [isExternalManager])

  useEffect(() => {
    if (fillOrderInformation.status == 'success') {
      reloadOrderData()
    }
    if (fillExternalManagerInformation.status == 'success') {
      setIsEditingDocumentManager(false)
      setIsEditingMailManager(false)
      setIsEditingNameManager(false)
      setIsEditingPhoneManager(false)
      reloadOrderData()
    }
  }, [fillOrderInformation.status, fillExternalManagerInformation.status])

  useEffect(() => {
    if (deleteExternalManager.isSuccess) {
      setName(null)
      setMail(null)
      setPhone(null)
      setDocument(null)
      setIsEditingNameManager(false)
      setIsEditingMailManager(false)
      setIsEditingPhoneManager(false)
      setIsEditingDocumentManager(false)

      reloadOrderData()
    }
  }, [deleteExternalManager.isSuccess])

  const handleExternalManagerChange = () => {
    const externalManagerData = {
      orderId: orderData.id,
      data: { name: name, document: document, mail: mail, phone: phone },
    }
    fillExternalManagerInformation.mutate(externalManagerData)
  }

  const hasEmptyOrNullFields = (fieldList) => {
    return fieldList?.some((field) => field === null || field === '')
  }

  const allowedInsurers = ['Porto Seguro', 'Sulamérica', 'Alice']

  return (
    <ExternalMain>
      <ExternalWrapper>
        <WrapperExternalDiv>
          <p>Supervisor - Deseja alterar?</p>
          <SwitchButton
            checked={isExternalManager}
            onClick={() => setIsExternalManager(!isExternalManager)}
            color="#514984"
          />
        </WrapperExternalDiv>

        {isEditingNameManager && isExternalManager ? (
          <FillInsurerIdInputDiv>
            <FillInsurerIdInputLabel>Supervisor na Operadora:* </FillInsurerIdInputLabel>

            <FillInsurerIdInput
              type="text"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              onChange={(event) => setName(event.target.value)}
              defaultValue={orderData?.externalManager?.name ?? undefined}
            />
            <SaveInsurerIdButtonDiv>
              <Button
                height="30px"
                backgroundColor={colors.darkPurple}
                loading={fillExternalManagerInformation?.isPending || isLoadingReloadOrderData}
                onClick={() => handleExternalManagerChange()}
              >
                <CheckOutlined style={{ color: 'white' }} />
              </Button>
            </SaveInsurerIdButtonDiv>
          </FillInsurerIdInputDiv>
        ) : (
          <EditInfoButton
            isValidate={validateOrderDispatch && orderData?.externalManager?.name === null}
            activeHover={isExternalManager}
            onClick={() => setIsEditingNameManager(true)}
          >
            <p>
              Supervisor na Operadora:*{' '}
              <span>
                {isExternalManager
                  ? orderData?.externalManager?.name ?? 'Nome Completo'
                  : getBrokerinfoQuery?.data?.manager?.name}
              </span>
            </p>
            {isExternalManager && <EditFilled />}
          </EditInfoButton>
        )}

        {isEditingDocumentManager && isExternalManager ? (
          <>
            <FillInsurerIdInputDiv>
              <FillInsurerIdInputLabel>CPF Supervisor:*</FillInsurerIdInputLabel>
              <FillInsurerIdInput
                type="text"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={(event) => setDocument(event.target.value)}
                defaultValue={orderData?.externalManager?.document ?? undefined}
              />
              <SaveInsurerIdButtonDiv>
                <Button
                  height="30px"
                  backgroundColor={colors.darkPurple}
                  loading={fillExternalManagerInformation?.isPending || isLoadingReloadOrderData}
                  onClick={() => handleExternalManagerChange()}
                >
                  <CheckOutlined style={{ color: 'white' }} />
                </Button>
              </SaveInsurerIdButtonDiv>
            </FillInsurerIdInputDiv>
          </>
        ) : (
          <EditInfoButton
            isValidate={validateOrderDispatch && orderData?.externalManager?.document === null}
            activeHover={isExternalManager}
            onClick={() => setIsEditingDocumentManager(true)}
          >
            <p>
              CPF do supervisor:* {''}
              <span>
                {isExternalManager
                  ? orderData?.externalManager?.document ?? '000.000.000-00'
                  : getBrokerinfoQuery?.data?.personalInformation?.document}
              </span>
            </p>
            {isExternalManager && <EditFilled />}
          </EditInfoButton>
        )}
        {isEditingMailManager && isExternalManager ? (
          <>
            <FillInsurerIdInputDiv>
              <FillInsurerIdInputLabel>E-mail do supervisor:*</FillInsurerIdInputLabel>
              <FillInsurerIdInput
                type="text"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={(event) => setMail(event.target.value)}
                defaultValue={orderData?.externalManager?.mail ?? undefined}
              />
              <SaveInsurerIdButtonDiv>
                <Button
                  height="30px"
                  backgroundColor={colors.darkPurple}
                  loading={fillExternalManagerInformation?.isPending || isLoadingReloadOrderData}
                  onClick={() => handleExternalManagerChange()}
                >
                  <CheckOutlined style={{ color: 'white' }} />
                </Button>
              </SaveInsurerIdButtonDiv>
            </FillInsurerIdInputDiv>
          </>
        ) : (
          allowedInsurers.includes(orderData?.quotation?.insurer ?? '') && (
            <EditInfoButton
              isValidate={validateOrderDispatch && orderData?.externalManager?.mail === null}
              activeHover={isExternalManager}
              onClick={() => setIsEditingMailManager(true)}
            >
              <p>
                E-mail do supervisor:*{' '}
                <span>
                  {isExternalManager
                    ? orderData?.externalManager?.mail ?? 'exemplo@email.com'
                    : getBrokerinfoQuery?.data?.personalInformation?.mail}
                </span>
              </p>
              {isExternalManager && <EditFilled />}
            </EditInfoButton>
          )
        )}

        {isEditingPhoneManager && isExternalManager ? (
          <>
            <FillInsurerIdInputDiv>
              <FillInsurerIdInputLabel>Telefone do supervisor:*</FillInsurerIdInputLabel>
              <FillInsurerIdInput
                type="text"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={(event) => setPhone(event.target.value)}
                defaultValue={orderData?.externalManager?.phone ?? undefined}
              />
              <SaveInsurerIdButtonDiv>
                <Button
                  height="30px"
                  backgroundColor={colors.darkPurple}
                  loading={fillExternalManagerInformation?.isPending || isLoadingReloadOrderData}
                  onClick={() => handleExternalManagerChange()}
                >
                  <CheckOutlined style={{ color: 'white' }} />
                </Button>
              </SaveInsurerIdButtonDiv>
            </FillInsurerIdInputDiv>
          </>
        ) : (
          allowedInsurers.includes(orderData?.quotation?.insurer ?? '') && (
            <EditInfoButton
              isValidate={validateOrderDispatch && orderData?.externalManager?.phone === null}
              activeHover={isExternalManager}
              onClick={() => setIsEditingPhoneManager(true)}
            >
              <p>
                Telefone do supervisor:*{' '}
                <span>
                  {isExternalManager
                    ? orderData?.externalManager?.phone ?? '(00) 00000 0000'
                    : getBrokerinfoQuery?.data?.personalInformation?.phone}
                </span>
              </p>
              {isExternalManager && <EditFilled />}
            </EditInfoButton>
          )
        )}

        {validateOrderDispatch &&
          hasEmptyOrNullFields(getRequiredExternalManagerFields(orderData)) && (
            <ErrorStyled>
              <WarningFilled style={{ color: colors.red }} />
              Dado obrigatório
            </ErrorStyled>
          )}
      </ExternalWrapper>
    </ExternalMain>
  )
}
