export const urlToBlob = async (url): Promise<Blob | undefined> => {
  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`Erro ao buscar o arquivo: ${response.statusText}`)
    }

    const blob = await response.blob()
    return blob
  } catch (error) {
    console.error('Erro ao transformar URL em Blob:', error)
  }
}
