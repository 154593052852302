import { useEffect, useState } from 'react'

import { CheckOutlined, EditFilled, WarningFilled } from '@ant-design/icons'
import { colors } from '@design-system/styles/theme'
import { Button, SwitchButton } from 'design-system/components'

import { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { useDeleteExternalBroker } from '@/App/clients/healthPlansOrders/mutations/deleteExternalBroker'
import { useFillExternalBrokerInformation } from '@/App/clients/healthPlansOrders/mutations/fillExternalBrokerInformation'
import { useFillOrderInformation } from '@/App/clients/healthPlansOrders/mutations/fillOrderInformation'
import { useGetBrokerByIdQuery } from '@/App/clients/healthPlansOrders/queries/brokerById'

import { getRequiredExternalBrokerFields } from '../../entities/entityAccordion/helper/helper'
import {
  EditInfoButton,
  ErrorStyled,
  ExternalMain,
  ExternalWrapper,
  FillInsurerIdInput,
  FillInsurerIdInputDiv,
  FillInsurerIdInputLabel,
  SaveInsurerIdButtonDiv,
  WrapperExternalDiv,
} from '../style'

export interface ExternalPersonsProps {
  orderData: GetOrderInformationBody
  reloadOrderData: () => void
  validateOrderDispatch?: boolean
  isLoadingReloadOrderData: boolean
}

export const BrokerPersonType: React.FC<ExternalPersonsProps> = ({
  orderData,
  reloadOrderData,
  validateOrderDispatch,
  isLoadingReloadOrderData,
}) => {
  const [isExternalBroker, setIsExternalBroker] = useState(orderData?.externalBroker !== null)

  const [isEditingName, setIsEditingName] = useState(false)
  const [isEditingDocument, setIsEditingDocument] = useState(false)
  const [isEditingMail, setIsEditingMail] = useState(false)
  const [isEditingPhone, setIsEditingPhone] = useState(false)
  const [isEditingBirthDate, setIsEditingBirthDate] = useState(false)

  const [name, setName] = useState<string | null>(null)
  const [document, setDocument] = useState<string | null>(null)
  const [mail, setMail] = useState<string | null>(null)
  const [phone, setPhone] = useState<string | null>(null)
  const [birthDate, setBirthDate] = useState<string | null>(null)

  const fillOrderInformation = useFillOrderInformation()
  const getBrokerinfoQuery = useGetBrokerByIdQuery(orderData?.broker?.id)
  const fillExternalBrokerInformation = useFillExternalBrokerInformation()

  const deleteExternalBroker = useDeleteExternalBroker()

  function formatDate(dataISO: string): string | undefined {
    if (!dataISO) return undefined

    const [ano, mes, dia] = dataISO.split('T')[0].split('-')
    return `${dia}/${mes}/${ano}`
  }

  useEffect(() => {
    if (!isExternalBroker && orderData?.externalBroker !== null) {
      const externalBrokerData = {
        orderId: orderData.id,
        externalBrokerId: orderData.externalBroker?.id,
      }
      deleteExternalBroker.mutate(externalBrokerData)
    }
  }, [isExternalBroker])

  const handleExternalBrokerChange = () => {
    const externalBrokerData = {
      orderId: orderData.id,
      data: { name, document, mail, phone, birthDate },
    }
    fillExternalBrokerInformation.mutate(externalBrokerData)
  }

  useEffect(() => {
    if (fillOrderInformation.status == 'success') {
      reloadOrderData()
    }
    if (fillExternalBrokerInformation.status == 'success') {
      setIsEditingDocument(false)
      setIsEditingMail(false)
      setIsEditingName(false)
      setIsEditingPhone(false)
      setIsEditingBirthDate(false)
      reloadOrderData()
    }
  }, [fillOrderInformation.status, fillExternalBrokerInformation.status])

  useEffect(() => {
    if (deleteExternalBroker.isSuccess) {
      setName(null)
      setMail(null)
      setPhone(null)
      setDocument(null)
      setBirthDate(null)
      setIsEditingName(false)
      setIsEditingMail(false)
      setIsEditingPhone(false)
      setIsEditingDocument(false)
      setIsEditingBirthDate(false)

      reloadOrderData()
    }
  }, [deleteExternalBroker.isSuccess])

  const hasEmptyOrNullFields = (fieldList) => {
    return fieldList?.some((field) => field === null || field === '')
  }

  const allowedInsurers = ['Porto Seguro', 'Sulamérica', 'Alice']

  return (
    <ExternalMain>
      <ExternalWrapper>
        <WrapperExternalDiv>
          <p>Vendedor - Deseja alterar?</p>
          <SwitchButton
            checked={isExternalBroker}
            onClick={() => setIsExternalBroker(!isExternalBroker)}
            color="#514984"
          />
        </WrapperExternalDiv>

        {isEditingName && isExternalBroker ? (
          <FillInsurerIdInputDiv>
            <FillInsurerIdInputLabel>Vendedor na Operadora:* </FillInsurerIdInputLabel>

            <FillInsurerIdInput
              type="text"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              onChange={(event) => setName(event.target.value)}
              defaultValue={orderData?.externalBroker?.name ?? undefined}
            />
            <SaveInsurerIdButtonDiv>
              <Button
                height="30px"
                backgroundColor={colors.darkPurple}
                loading={fillExternalBrokerInformation?.isPending || isLoadingReloadOrderData}
                onClick={() => handleExternalBrokerChange()}
              >
                <CheckOutlined style={{ color: 'white' }} />
              </Button>
            </SaveInsurerIdButtonDiv>
          </FillInsurerIdInputDiv>
        ) : (
          <EditInfoButton
            isValidate={validateOrderDispatch && orderData?.externalBroker?.name === null}
            activeHover={isExternalBroker}
            onClick={() => setIsEditingName(true)}
          >
            <p>
              Vendedor na Operadora:*{' '}
              <span>
                {isExternalBroker
                  ? orderData?.externalBroker?.name ?? 'Nome Completo'
                  : getBrokerinfoQuery?.data?.name}
              </span>
            </p>
            {isExternalBroker && <EditFilled />}
          </EditInfoButton>
        )}

        {isEditingDocument && isExternalBroker ? (
          <>
            <FillInsurerIdInputDiv>
              <FillInsurerIdInputLabel>CPF Vendedor:*</FillInsurerIdInputLabel>
              <FillInsurerIdInput
                type="text"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={(event) => setDocument(event.target.value)}
                defaultValue={orderData?.externalBroker?.document ?? undefined}
              />
              <SaveInsurerIdButtonDiv>
                <Button
                  height="30px"
                  backgroundColor={colors.darkPurple}
                  loading={fillExternalBrokerInformation?.isPending || isLoadingReloadOrderData}
                  onClick={() => handleExternalBrokerChange()}
                >
                  <CheckOutlined style={{ color: 'white' }} />
                </Button>
              </SaveInsurerIdButtonDiv>
            </FillInsurerIdInputDiv>
          </>
        ) : (
          <EditInfoButton
            isValidate={validateOrderDispatch && orderData?.externalBroker?.document === null}
            activeHover={isExternalBroker}
            onClick={() => setIsEditingDocument(true)}
          >
            <p>
              CPF do vendedor:* {''}
              <span>
                {isExternalBroker
                  ? orderData?.externalBroker?.document ?? '000.000.000-00'
                  : getBrokerinfoQuery?.data?.personalInformation?.document}
              </span>
            </p>
            {isExternalBroker && <EditFilled />}
          </EditInfoButton>
        )}
        {isEditingMail && isExternalBroker ? (
          <>
            <FillInsurerIdInputDiv>
              <FillInsurerIdInputLabel>E-mail do vendedor:*</FillInsurerIdInputLabel>
              <FillInsurerIdInput
                type="text"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={(event) => setMail(event.target.value)}
                defaultValue={orderData?.externalBroker?.mail ?? undefined}
              />
              <SaveInsurerIdButtonDiv>
                <Button
                  height="30px"
                  backgroundColor={colors.darkPurple}
                  loading={fillExternalBrokerInformation?.isPending || isLoadingReloadOrderData}
                  onClick={() => handleExternalBrokerChange()}
                >
                  <CheckOutlined style={{ color: 'white' }} />
                </Button>
              </SaveInsurerIdButtonDiv>
            </FillInsurerIdInputDiv>
          </>
        ) : (
          allowedInsurers.includes(orderData?.quotation?.insurer ?? '') && (
            <EditInfoButton
              isValidate={validateOrderDispatch && orderData?.externalBroker?.mail === null}
              activeHover={isExternalBroker}
              onClick={() => setIsEditingMail(true)}
            >
              <p>
                E-mail do vendedor:*{' '}
                <span>
                  {isExternalBroker
                    ? orderData?.externalBroker?.mail ?? 'exemplo@email.com'
                    : getBrokerinfoQuery?.data?.personalInformation?.mail}
                </span>
              </p>
              {isExternalBroker && <EditFilled />}
            </EditInfoButton>
          )
        )}

        {isEditingPhone && isExternalBroker ? (
          <>
            <FillInsurerIdInputDiv>
              <FillInsurerIdInputLabel>Telefone do vendedor:*</FillInsurerIdInputLabel>
              <FillInsurerIdInput
                type="text"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={(event) => setPhone(event.target.value)}
                defaultValue={orderData?.externalBroker?.phone ?? undefined}
              />
              <SaveInsurerIdButtonDiv>
                <Button
                  height="30px"
                  backgroundColor={colors.darkPurple}
                  loading={fillExternalBrokerInformation?.isPending || isLoadingReloadOrderData}
                  onClick={() => handleExternalBrokerChange()}
                >
                  <CheckOutlined style={{ color: 'white' }} />
                </Button>
              </SaveInsurerIdButtonDiv>
            </FillInsurerIdInputDiv>
          </>
        ) : (
          allowedInsurers.includes(orderData?.quotation?.insurer ?? '') && (
            <EditInfoButton
              isValidate={validateOrderDispatch && orderData?.externalBroker?.phone === null}
              activeHover={isExternalBroker}
              onClick={() => setIsEditingPhone(true)}
            >
              <p>
                Telefone do vendedor:*{' '}
                <span>
                  {isExternalBroker
                    ? orderData?.externalBroker?.phone ?? '(00) 00000 0000'
                    : getBrokerinfoQuery?.data?.personalInformation?.phone}
                </span>
              </p>
              {isExternalBroker && <EditFilled />}
            </EditInfoButton>
          )
        )}
        {isEditingBirthDate && isExternalBroker ? (
          <>
            <FillInsurerIdInputDiv>
              <FillInsurerIdInputLabel>E-mail do vendedor:*</FillInsurerIdInputLabel>
              <FillInsurerIdInput
                type="date"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={(event) => setBirthDate(event.target.value)}
                defaultValue={orderData?.externalBroker?.birthDate ?? undefined}
              />
              <SaveInsurerIdButtonDiv>
                <Button
                  height="30px"
                  backgroundColor={colors.darkPurple}
                  loading={fillExternalBrokerInformation?.isPending || isLoadingReloadOrderData}
                  onClick={() => handleExternalBrokerChange()}
                >
                  <CheckOutlined style={{ color: 'white' }} />
                </Button>
              </SaveInsurerIdButtonDiv>
            </FillInsurerIdInputDiv>
          </>
        ) : (
          orderData?.quotation?.insurer === 'Alice' && (
            <EditInfoButton
              isValidate={validateOrderDispatch && orderData?.externalBroker?.birthDate === null}
              activeHover={isExternalBroker}
              onClick={() => setIsEditingBirthDate(true)}
            >
              <p>
                Data de nascimento:*{' '}
                <span>
                  {isExternalBroker
                    ? formatDate(orderData?.externalBroker?.birthDate as string) ?? 'DD/MM/AAAA'
                    : formatDate(
                        getBrokerinfoQuery?.data?.personalInformation?.birthDate as string,
                      )}
                </span>
              </p>
              {isExternalBroker && <EditFilled />}
            </EditInfoButton>
          )
        )}
        {validateOrderDispatch &&
          hasEmptyOrNullFields(getRequiredExternalBrokerFields(orderData)) && (
            <ErrorStyled>
              <WarningFilled style={{ color: colors.red }} />
              Dado obrigatório
            </ErrorStyled>
          )}
      </ExternalWrapper>
    </ExternalMain>
  )
}
