import { dependentAliceHealthSchema } from './alice'
import { dependentAmilHealthSchema } from './amil'
import { dependentBradescoHealthSchema } from './bradesco'
import { dependentDefaultHealthSchema } from './default'
import { dependentGndiHealthSchema } from './gndi'
import { dependentPortoSeguroHealthSchema } from './portoSeguro'
import { dependentSulamericaHealthSchema } from './sulamerica'

export const dependentHealthSchemas = {
  amil: dependentAmilHealthSchema,
  alice: dependentAliceHealthSchema,
  gndi: dependentGndiHealthSchema,
  portoSeguro: dependentPortoSeguroHealthSchema,
  sulamerica: dependentSulamericaHealthSchema,
  bradesco: dependentBradescoHealthSchema,
  default: dependentDefaultHealthSchema,
}
