import styled from 'styled-components'

import { colors } from '../../../../../../design-system/src/styles/theme'

export interface AccordionChildrenDivProps {
  backgroundColor?: string
}

export const DescriptionLabel = styled.div`
  font-size: 12px;
  color: #858aa3;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 60px;
`

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
`

export const FormItemSingle = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-bottom: 30px;
`
export const FormDiv = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  justify-content: center;
  align-items: center;
`

export const FormItemPorto = styled.div`
  margin-bottom: 30px;
`

export const CheckboxWrapper = styled.div`
  margin-top: 32px;
`

export const FormItemDocument = styled(FormItem)`
  align-self: center;
  margin-top: 18px;
`

export const FormItemLabel = styled.p`
  font-weight: 500;
  color: ${colors.primaryText};
  text-align: start;
  margin: 0;
  margin-bottom: 2px;
`

export const FormItemRadio = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  margin-bottom: 30px;
`

export const FormItemRadioRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 7px;
`

export const FormItemRadioColumn = styled(FormItemRadioRow)`
  flex-direction: column;
`

export const HolderGroupAccordionsDiv = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
`
export const FormErrorWrapper = styled.div`
  margin-top: 10px;
`

export const AccordionSubtitles = styled.h3`
  margin: 16px 0 0 0;
  color: #4b4b4b;
  font-size: 15px;
  font-weight: 600;
`

export const HorizontalLine = styled.hr`
  height: 1px;
  border-width: 0;
  margin-top: 0;
  background-color: #cbcedc;
  width: 100%;
`
