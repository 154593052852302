import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '../../http'

type UploadFileByURLRequest = {
  uploadURL: string
  data: Blob | undefined
  fileContentType: string
}

async function uploadFileByURL({ uploadURL, data, fileContentType }: UploadFileByURLRequest) {
  const response = await api.put(uploadURL, data, {
    headers: {
      'Content-Type': fileContentType,
    },
  })
  return response.data
}

export const useUploadFileByURL = () => {
  return useMutation({
    mutationKey: ['uploadURL'],
    mutationFn: uploadFileByURL,
    onSuccess: () => {
      triggerToast(true, 'Upload feito com sucesso!')
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro durante o upload.')
    },
  })
}
