import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { CheckOutlined, EditFilled, WarningFilled } from '@ant-design/icons'
import { colors } from '@design-system/styles/theme'
import dayjs from 'dayjs'
import { Button, DatePicker, Modal, Skeleton, SwitchButton } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import {
  FillOrderInformationData,
  useFillOrderInformation,
} from '@/App/clients/healthPlansOrders/mutations/fillOrderInformation'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import { RiskAnalysisModal } from '@/App/components/Modal/OrderStatus/RiskAnalysis'

import { ErrorStyled } from '../quotation/style'
import {
  ActionsDiv,
  EditInsurerIdButton,
  FillInsurerIdInput,
  FormItem,
  FormItemLabel,
  HorizontalLine,
  OtherInfosActionsContainer,
  OtherInfosDiv,
  OtherInfosHeader,
  RiskSwitcherContainer,
  RiskSwitcherLabel,
  SaveInsurerIdButtonDiv,
  StyledItemsBox,
} from './style'

export interface OtherInfoProps {
  orderData?: GetOrderInformationBody
  orderId?: string
  validateSiswebDispatch: boolean
}

export const OtherInfos: React.FC<OtherInfoProps> = ({
  orderId,
  orderData,
  validateSiswebDispatch,
}) => {
  const { control, watch } = useForm<FillOrderInformationData>()
  const formValue = watch()

  const [isEditingDispatchedAt, setIsEditingDispatchedAt] = useState(false)
  const [isEditingInsurerId, setIsEditingInsurerId] = useState(false)
  const [isEditingOriginId, setIsEditingOriginId] = useState(false)
  const [insurerId, setInsurerId] = useState<string | null>(null)
  const [isRiskIdentifiedModalOpen, setIsRiskIdentifiedModalOpen] = useState(false)
  const [originId, setOriginId] = useState<string | null>(null)

  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const fillInformationRequest = useFillOrderInformation()

  const fillInsurerId = () => {
    fillInformationRequest.mutateAsync({
      orderId,
      insurerId,
    })
  }

  const fillDispatchedAt = () => {
    fillInformationRequest.mutateAsync({
      orderId,
      dispatchedAt: formValue.dispatchedAt,
    })
  }

  const fillOriginId = () => {
    fillInformationRequest.mutateAsync({
      orderId,
      originId,
    })
  }

  useEffect(() => {
    if (orderData != null) {
      setInsurerId(orderData.insurerId ?? null)
      setOriginId(orderData.originId ?? null)
    }
  }, [orderData])

  useEffect(() => {
    if (fillInformationRequest.isSuccess) {
      orderInformationQuery.refetch()
      triggerToast(true, 'Atualização realizada com sucesso!')
      setIsEditingInsurerId(false)
      setIsEditingDispatchedAt(false)
      setIsEditingOriginId(false)
    }
    if (fillInformationRequest.isError) {
      triggerToast(false, 'Ops, ocorreu um erro ao realizar a atualização!')
    }
  }, [fillInformationRequest.isSuccess, fillInformationRequest.isError])

  return (
    <>
      <OtherInfosDiv>
        {orderData == null ? (
          <Skeleton
            type="default"
            shape="square"
            active={true}
            loading={true}
            title={{ width: '400px' }}
          />
        ) : (
          <>
            <OtherInfosHeader>
              <span> Outras Informações </span>
            </OtherInfosHeader>
            <HorizontalLine />

            <OtherInfosActionsContainer>
              <ActionsDiv>
                <StyledItemsBox>
                  {isEditingInsurerId ? (
                    <>
                      <FormItem>
                        <FormItemLabel>Nº da proposta na operadora</FormItemLabel>
                        <FillInsurerIdInput
                          type="text"
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus
                          onChange={(event) =>
                            setInsurerId(event.target.value.replaceAll(/[^A-Za-z0-9_-]/g, ''))
                          }
                          value={insurerId ?? undefined}
                        />
                        <SaveInsurerIdButtonDiv>
                          <Button
                            height="30px"
                            backgroundColor={colors.darkPurple}
                            loading={fillInformationRequest.isPending}
                            onClick={fillInsurerId}
                          >
                            <CheckOutlined style={{ color: 'white' }} />
                          </Button>
                        </SaveInsurerIdButtonDiv>
                      </FormItem>
                    </>
                  ) : (
                    <>
                      <EditInsurerIdButton onClick={() => setIsEditingInsurerId(true)}>
                        <FormItemLabel>
                          Nº da proposta na operadora:
                          <span>{orderData?.insurerId ?? '-'}</span>
                        </FormItemLabel>
                        <EditFilled />
                      </EditInsurerIdButton>
                      {validateSiswebDispatch && orderData?.insurerId === null && (
                        <ErrorStyled>
                          <WarningFilled style={{ color: colors.red }} />
                          Dado obrigatório
                        </ErrorStyled>
                      )}
                    </>
                  )}
                </StyledItemsBox>
                <StyledItemsBox>
                  {isEditingDispatchedAt ? (
                    <>
                      <FormItem>
                        <FormItemLabel>Data produção*</FormItemLabel>
                        <DatePicker name="dispatchedAt" control={control} />

                        <SaveInsurerIdButtonDiv>
                          <Button
                            height="30px"
                            backgroundColor={colors.darkPurple}
                            loading={fillInformationRequest.isPending}
                            onClick={fillDispatchedAt}
                          >
                            <CheckOutlined style={{ color: 'white' }} />
                          </Button>
                        </SaveInsurerIdButtonDiv>
                      </FormItem>
                    </>
                  ) : (
                    <>
                      <EditInsurerIdButton onClick={() => setIsEditingDispatchedAt(true)}>
                        <FormItemLabel>
                          Data produção:
                          <span>
                            {orderData?.dispatchedAt
                              ? dayjs(orderData?.dispatchedAt).format('DD/MM/YYYY')
                              : '-'}
                          </span>
                        </FormItemLabel>
                        <EditFilled />
                      </EditInsurerIdButton>
                      {validateSiswebDispatch && orderData?.dispatchedAt === null && (
                        <ErrorStyled>
                          <WarningFilled style={{ color: colors.red }} />
                          Dado obrigatório
                        </ErrorStyled>
                      )}
                    </>
                  )}
                </StyledItemsBox>
                <StyledItemsBox>
                  {isEditingOriginId ? (
                    <>
                      <FormItem>
                        <FormItemLabel>Movidesk ID*</FormItemLabel>
                        <FillInsurerIdInput
                          type="text"
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus
                          onChange={(event) =>
                            setOriginId(event.target.value.replaceAll(/[^A-Za-z0-9_-]/g, ''))
                          }
                          value={originId ?? undefined}
                        />

                        <SaveInsurerIdButtonDiv>
                          <Button
                            height="30px"
                            backgroundColor={colors.darkPurple}
                            loading={fillInformationRequest.isPending}
                            onClick={fillOriginId}
                          >
                            <CheckOutlined style={{ color: 'white' }} />
                          </Button>
                        </SaveInsurerIdButtonDiv>
                      </FormItem>
                    </>
                  ) : (
                    <>
                      <FormItemLabel>
                        Movidesk ID:
                        {/* TODO: Implementar edição de originId */}
                        <span>{orderData?.originId ?? '-'}</span>
                        {orderData.origin === 'INTRANET' && (
                          <EditInsurerIdButton onClick={() => setIsEditingOriginId(true)}>
                            <EditFilled />
                          </EditInsurerIdButton>
                        )}
                      </FormItemLabel>
                    </>
                  )}
                </StyledItemsBox>
              </ActionsDiv>

              <RiskSwitcherContainer>
                <SwitchButton
                  disabled={orderInformationQuery?.data?.isRiskDetected ?? false}
                  checked={orderInformationQuery?.data?.isRiskDetected ?? false}
                  onChange={() => setIsRiskIdentifiedModalOpen(true)}
                  color="#514984"
                />
                <RiskSwitcherLabel>Risco identificado?</RiskSwitcherLabel>
              </RiskSwitcherContainer>
            </OtherInfosActionsContainer>
          </>
        )}
      </OtherInfosDiv>
      <Modal
        isOpen={isRiskIdentifiedModalOpen}
        width={800}
        setIsOpen={() => setIsRiskIdentifiedModalOpen(false)}
        footer={false}
      >
        <RiskAnalysisModal
          orderId={orderId}
          setIsModalOpen={() => setIsRiskIdentifiedModalOpen(false)}
          isManualDetection
        />
      </Modal>
    </>
  )
}
