import { SearchInput } from 'design-system/components'

import { Action } from '@/App/pages/brokers'

import { SearchInputWrapper } from './style'

interface Props {
  handleRequest: (action: Action, filterValues: [{ key: string; value: string }]) => void
  placeHolder: string
}

export const MovideskFilter = ({ placeHolder, handleRequest }: Props) => {
  return (
    <>
      <SearchInputWrapper>
        <SearchInput
          placeholder={placeHolder}
          onSearch={(value) => handleRequest(Action.ReplaceFilter, [{ key: 'originId', value }])}
        />
      </SearchInputWrapper>
    </>
  )
}
